import { makeAutoObservable, toJS } from "mobx";
import { fusionRecsApi } from "../api";
import { uiStore, rolesStore } from "./Store";
import { isUserAuthorized } from "../components/AuthorizationWrapper";
import { AUTHORITIES } from "../utils/enums";

/**
 * Contains data for clients for a team
 * Stored as a list of clients
 * Should be updated each time a team is changed
 */
class SubAccountStore {
  subAccounts = [];

  constructor() {
    makeAutoObservable(this);
  }

  getAllSubAccountsForTeam = async (teamId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_VIEW, AUTHORITIES.RECS_SUB_ACCOUNT_VIEW]) })) {
      console.log("getAllSubAccountsForTeam: unauthorised");
      return [];
    }
    let result = [];
    const resp = await fusionRecsApi.subAccount.getAllSubAccountsForTeam(teamId);
    if (resp.status === 200) {
      result = resp.data;
      this.subAccounts = result;
    }
    return result;
  };

  getSubAccountsAndAliasesForTeam = async (teamId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_SUB_ACCOUNT_VIEW]) })) {
      console.log("getSubAccountsAndAliasesForTeam: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.subAccount.getSubAccountsAndAliasesForTeam(teamId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  createSubAccount = async (teamId, body) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_SUB_ACCOUNT_CREATE]) })) {
      console.log("createSubAccount: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.subAccount.createSubAccount(teamId, body);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    } else {
      this.newSubAccount = resp.data;
    }
    return resp;
  };

  deleteSubAccount = async (teamId, subAccountId, version) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_SUB_ACCOUNT_DELETE]) })) {
      console.log("deleteSubAccount: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.subAccount.deleteSubAccount(teamId, subAccountId, version);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  getNewSubAccount = () => {
    return toJS(this.newSubAccount);
  };

  removeNewSubAccount = () => {
    this.newSubAccount = null;
  };

  updateVersion = (id, version) => {
    this.subAccounts = this.subAccounts.map((subAccount) => {
      if (subAccount.id === id && subAccount.version >= 0) {
        subAccount = { ...subAccount, version: version };
      }
      return subAccount;
    });
  };
}

export { SubAccountStore };
