import { useState, useRef } from "react";
import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";
import { R } from "@fundrecs/ui-library";
import { ManageLayout, PageTitleArea } from "../layout/Layout";
import { useStore } from "../../store/Store.js";
import { Table } from "../ag-grid/Ag-grid";

const ImportErrors = observer(({}) => {
  const gridRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [file, setFile] = useState({ fileName: "" });
  const [initialised, setInitialised] = useState(false);
  const [colDefs, setColDefs] = useState(null);
  const { uploadedFileStore } = useStore();
  const teamId = searchParams.get("teamId") ? searchParams.get("teamId") : null;
  const fileId = searchParams.get("fileId") ? searchParams.get("fileId") : null;

  const getFileById = async (fileId) => {
    const response = await uploadedFileStore.getUploadedFileById(teamId, fileId);
    if (response.status === 200) {
      setFile(response.data);
    }
    const response2 = await uploadedFileStore.getUploadedFileRowCountsById(teamId, fileId);
    if (response2.status === 200) {
      setFile({ ...response.data, ...response2.data });
    }
  };

  const processFileErrors = (file) => {
    return file && file.errors
      ? file.errors.map((error) => {
          return { errorName: error.description, count: error.count, code: error.code };
        })
      : [];
  };

  //Call the endpoint to get error rows to get the schema for the table
  const fetchColDefs = async (fileId) => {
    const response = await uploadedFileStore.getRowDataWithErrors(teamId, fileId, "test");
    if (response.status === 200) {
      setColDefs(
        response.data.schema.map((it) => {
          return { headerName: it.name, field: it.name, resizable: true };
        })
      );
    }
  };

  const onClickExpandRows = async (params) => {
    let rows = [];
    if (params.data && params.data.code) {
      const resp = await uploadedFileStore.getRowDataWithErrors(teamId, fileId, params.data.code);
      console.log(resp);
      if (resp.status === 200) {
        rows = resp.data.rows.map((it) => it.data);
      }
    }
    return rows;
  };

  if (!initialised && fileId) {
    setInitialised(true);
    getFileById(fileId);
    fetchColDefs(fileId);
  }

  return (
    <>
      <PageTitleArea title="Import errors" description={file.fileName} borderBottom={false} props={""} />
      <ManageLayout headerTabs={""} mainContentToolbar={""} panelHeader={<></>} pageTitleArea={<></>} panelTabs={<></>}>
        <>
          <R props="mt-16">
            {colDefs === null ? (
              ""
            ) : (
              <Table
                rowSelection="multiple"
                columnDefs={[
                  { headerName: "Error", field: "errorName", cellRenderer: "agGroupCellRenderer" },
                  { headerName: "Count", field: "count" },
                ]}
                sizeToFit={true}
                rowData={file && ![undefined, null].includes(file.errors) ? processFileErrors(file) : null}
                ref={gridRef}
                masterDetail={true}
                detailCellRendererParams={{
                  detailGridOptions: {
                    columnDefs: colDefs,
                    pagination: true,
                  },
                  getDetailRowData: async (params) => {
                    const rows = await onClickExpandRows(params);
                    params.successCallback(rows);
                  },
                }}
                paginationSize={0}
                detailRowHeight={400}
              />
            )}
          </R>
        </>
      </ManageLayout>
    </>
  );
});

export { ImportErrors };
