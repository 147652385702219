import { observer } from "mobx-react-lite";
import { R, Text } from "@fundrecs/ui-library";
import { RecTypesTable } from "../RecTypesTable";

const EnabledRecTypes = observer(({ teamId, account }) => {
  return (
    <>
      <div className="pt-32"></div>
      <Text size="lg">Reconciliation Types</Text>
      <Text weight="regular" size="sm" variant="secondary">
        Select the types of reconciliation you want to perform on this fund. Reconciliation types must be enabled before a reconciliation can take place.
      </Text>
      <div className="pt-32"></div>
      <Text>{`Enabled rec types for ${account ? account.name : ""}`}</Text>
      <R>
        <RecTypesTable account={account} showAccountConfig={true} />
      </R>
    </>
  );
});

export { EnabledRecTypes };
