const TEXT_FILTER = "agTextColumnFilter";
const FILTERS = { STRING: TEXT_FILTER, NUMBER: "agNumberColumnFilter", DATE: "agDateColumnFilter" };

const getTableHeaders = (columns, masterDetail = false) => {
  const headers = [];
  if (columns) {
    columns.forEach((header, index) => {
      const filter = FILTERS[header.produces] ? FILTERS[header.produces] : TEXT_FILTER;
      let cellRenderer = index === 0 && masterDetail ? { cellRenderer: "agGroupCellRenderer" } : {};
      headers.push({
        ...cellRenderer,
        headerName: header.name,
        field: header.name,
        filter: filter,
        cellStyle: header.name.indexOf("Difference") >= 0 ? { backgroundColor: "#F3F3F5" } : "",
        type: header.produces === "NUMBER" ? "rightAligned" : "",
        valueFormatter: (params) => {
          const numberValue = Number(params.data[header.name]);
          return header.produces === "NUMBER" && !isNaN(numberValue) ? numberValue.toLocaleString("en-US") : params.data[header.name];
        },
      });
    });
  }
  return headers;
};

const createTagsString = (notes, nameKey) => {
  let tagsString = "";
  notes.forEach((note) => {
    tagsString = `${tagsString}${tagsString.length ? "," : ""} ${note[nameKey]}`;
  });
  return tagsString;
};

const getUnmatchedRows = (unmatchedRows) => {
  return unmatchedRows.map((row) => {
    let rowData = { id: row.id };
    Object.entries(row.data).forEach((keyValue) => {
      rowData[keyValue[0]] = keyValue[1];
    });
    return rowData;
  });
};

const getMatchedRows = (rowGroups, matchingRules) => {
  return rowGroups.map((rowGroup) => {
    const matchingRule = matchingRules.find((it) => it.id === Number(rowGroup.matchingRuleId));
    let rowData = {
      ...rowGroup,
      id: rowGroup.id,
      version: rowGroup.version,
      tags: rowGroup.tags,
      Type: rowGroup.category ? rowGroup.category.name : "",
      Tags: createTagsString(rowGroup.tags, "name"),
      "Matching Rule": !rowGroup.matchingRuleId ? "Manually matched" : matchingRule ? matchingRule.name : "- Rule not found -",
      auxiliaryInfo: rowGroup.auxiliaryInfo,
      classifier: rowGroup.category,
    };
    Object.entries(rowGroup.data).forEach((keyValue) => {
      rowData[keyValue[0]] = keyValue[1];
    });
    return rowData;
  });
};

const formatUnmatchedRowsForTable = (rowData) => {
  return {
    columns: getTableHeaders(rowData.schema),
    rows: getUnmatchedRows(rowData.rows),
  };
};

const formatMatchedRowsForTable = (columns, rowData, matchingRules, masterDetail = false) => {
  return {
    columns: getTableHeaders(columns, masterDetail),
    rows: getMatchedRows(rowData, matchingRules),
  };
};

export { formatUnmatchedRowsForTable, formatMatchedRowsForTable };
