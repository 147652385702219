import { useState, useRef, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import {
  Text,
  R,
  C2,
  Button,
  Dropdown,
  DropdownButton,
  IconDownArrow,
  DropdownList,
  DropdownListItem,
  DropdownItem,
  IconDownload,
  ToolTip,
  DatePicker,
  modalInstance,
} from "@fundrecs/ui-library";
import { AUTHORITIES, MODALS } from "../../utils/enums.js";
import { ManageLayout } from "../layout/Layout.js";
import { PageTitleArea } from "../layout/Layout";
import { Table } from "../ag-grid/Ag-grid";
import { useStore } from "../../store/Store.js";
import { convertToDate, stringifyDate } from "../../utils/dates.js";
import { PATH } from "../../utils/urls.js";
import "./recTypeDash.css";
import { AuthWrapper } from "../AuthorizationWrapper.js";
import { ManualFileUploadModal } from "../uploads/ManualFileUploadModal.js";
import { StatusBadge } from "../reusable/StatusBadge.js";
import { ifNullUndefinedArray } from "../../utils/utils.js";

const RecTypeDash = observer(() => {
  const ALL_CLIENTS = "all";
  const { clientStore, recStore, teamStore, recTypeStore, meStore, rolesStore } = useStore();
  const gridRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedClient, setSelectedClient] = useState(ALL_CLIENTS);
  const [fundRows, setFundRows] = useState(null);
  const [fundRowsInitialised, setFundRowsInitialised] = useState(false);
  const [selectedDate, setSelectedDate] = useState(searchParams.get("date") ? convertToDate(searchParams.get("date")) : new Date());
  const [recTypeId, setRecTypeId] = useState(searchParams.get("recType") ? Number(searchParams.get("recType")) : null);
  const navigate = useNavigate();
  const selectedRecType = recTypeStore.getSelectedRecType();
  const recType = recTypeStore.getSelectedRecType();

  const selectedRecTypeName = selectedRecType ? selectedRecType.name : "";
  const recTypes = ifNullUndefinedArray(recTypeStore.getRecTypes());
  const [clientsAndAccounts, setClientsAndAccounts] = useState([]);
  const teamId = teamStore.getSelectedTeam().id;

  useEffect(() => {
    //Populate fund Table with data for all clients
    if (
      (!fundRowsInitialised && clientStore.getAllClients().length && recTypes.length) ||
      (recTypeId !== Number(searchParams.get("recType")) && recTypes.length)
    ) {
      let recTypeId = null;
      const recTypeName = searchParams.get("recTypeName") ? searchParams.get("recTypeName") : null;
      if (recTypeName) {
        const recType = recTypes.find((it) => it.name.split(" ").join("").toLowerCase() === recTypeName);

        if (!recType) {
          navigate(`${PATH.REC_TYPES}?teamId=${teamId}`, { replace: false });
          return;
        } else {
          recTypeId = recType.id;
        }
      } else {
        recTypeId = searchParams.get("recType") ? Number(searchParams.get("recType")) : null;
      }
      //const recTypeId = searchParams.get("recType") ? Number(searchParams.get("recType")) : null;
      const selectedRecType = recTypeStore.setSelectedRecTypeWithId(searchParams.get("teamId"), recTypeId);
      const clientObj = clientStore.findById(searchParams.get("client"));
      const client = [null, undefined, ALL_CLIENTS].includes(clientObj) ? ALL_CLIENTS : clientObj;
      navigate(
        `${PATH.REC_TYPE_DASH}?teamId=${teamId}&recType=${recTypeId}&client=${client === ALL_CLIENTS ? ALL_CLIENTS : client.id}&date=${stringifyDate(
          selectedDate
        )}`,
        { replace: true }
      );
      fetchAccountsForRecType(teamId, recTypeId, client, selectedRecType);
      setRecTypeId(recTypeId);
      setSelectedClient(client);
      setFundRowsInitialised(true);
    }
  });

  const fetchAccountsForRecType = async (teamId, recTypeId, client, selectedRecType) => {
    const response = await recTypeStore.fetchClientsAccountsPerRecType(teamId, recTypeId);
    if (response.status === 200) {
      setClientsAndAccounts(response.data);
      populateFundTable(client, selectedDate, selectedRecType, response.data);
    }
  };

  const cols = {
    overview: [
      { headerName: "Client", field: "client" },
      { headerName: "Fund", field: "fundName" },
      {
        headerName: "Rec Status",
        field: "recStatus",
        cellRenderer: (params) => {
          return (
            <Link
              className="text-decoration-none"
              to={
                params.data.recStatus === "-"
                  ? ""
                  : `${PATH.REC}?teamId=${teamId}&recTypeId=${recTypeId}&accId=${params.data.accountId}&recId=${params.data.recId}`
              }
            >
              <StatusBadge status={params.data.recStatus} onClick={() => {}} recStatus={true} />
            </Link>
          );
        },
      },
    ],
  };

  const selectClient = (client) => {
    navigate(
      `${PATH.REC_TYPE_DASH}?teamId=${teamId}&recType=${recTypeId}&client=${client !== ALL_CLIENTS ? client.id : ALL_CLIENTS}&date=${stringifyDate(
        selectedDate
      )}`,
      {
        replace: true,
      }
    );
    setSelectedClient(client);
    populateFundTable(client, selectedDate, selectedRecType, clientsAndAccounts);
  };

  const updateDateSelection = (date, onDateChange) => {
    navigate(
      `${PATH.REC_TYPE_DASH}?teamId=${teamId}&recType=${recTypeId}&client=${
        selectedClient !== ALL_CLIENTS ? selectedClient.id : ALL_CLIENTS
      }&date=${stringifyDate(date)}`,
      {
        replace: true,
      }
    );
    setSelectedDate(date);
    onDateChange(date);
  };

  const getRecs = async (date, client, selectedRecType) => {
    let accountList = client ? recTypeStore.getAccountsPerClient(client.id) : recTypeStore.getAccounts();

    let data = {
      dateFrom: stringifyDate(date),
      dateTill: stringifyDate(date),
      accountList: accountList.map((it) => it.uuid),
      recTypeId: selectedRecType.id,
    };
    return accountList.length ? recStore.getRecsInDateRange(teamId, data) : [];
  };

  const populateFundTableWithSelectedClient = (date) => {
    populateFundTable(selectedClient, date, selectedRecType, clientsAndAccounts);
  };

  const populateFundTable = async (client, date, selectedRecType, tableData) => {
    setFundRows(null);
    client = client === ALL_CLIENTS ? null : client;
    const data = await getRecs(date, client, selectedRecType);
    let fundsData = [];
    tableData.forEach((clientAccounts, index) => {
      if (client === null || client.id === clientAccounts.client.id) {
        const accounts = clientAccounts.accountList;
        accounts.forEach((accountData, index2) => {
          const account = accountData.account;
          const recs = data.find((obj) => obj.account === account.uuid);
          const recStatus = recs && recs.rec && recs.rec.fourEyes ? recs.rec.fourEyes.status : "-";
          const recId = recs ? recs.rec.id : null;
          fundsData.push({
            id: `${index}${index2}`,
            client: clientAccounts.client.name,
            clientId: clientAccounts.client.id,
            accountUuid: account.uuid,
            accountId: account.id,
            fundName: account.name,
            recStatus: recStatus,
            recId: recId,
          });
        });
      }
    });
    setFundRows(fundsData);
  };

  const mainToolbar = () => {
    return (
      <R props="pt-24 pb-16">
        <C2 props="pl-32">
          <DatePicker
            date={selectedDate}
            onDateChange={(date) => updateDateSelection(date, populateFundTableWithSelectedClient)}
            dateFormat={meStore.getUserDateFormat()}
          />
        </C2>
        <C2>
          <Dropdown>
            <DropdownButton size="sm">
              <span>Show: {selectedClient && selectedClient !== ALL_CLIENTS ? selectedClient.name : "All Clients"}</span>
              <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
            </DropdownButton>
            <DropdownList>
              <DropdownListItem
                onClick={() => {
                  selectClient(ALL_CLIENTS);
                }}
                key={0}
              >
                <DropdownItem active={null} index={0}>
                  <span>Select All</span>
                </DropdownItem>
              </DropdownListItem>
              {clientsAndAccounts.map((it, index) => {
                const client = it.client;
                return (
                  <DropdownListItem
                    onClick={() => {
                      selectClient(client);
                    }}
                    key={index + 1}
                  >
                    <DropdownItem active={null} index={0}>
                      <span>{client.name}</span>
                    </DropdownItem>
                  </DropdownListItem>
                );
              })}
            </DropdownList>
          </Dropdown>
        </C2>
      </R>
    );
  };

  const createFileName = () => {
    const fileName = `${selectedRecTypeName} Reconcilations - ${stringifyDate(selectedDate)} - ${
      selectedClient !== "all" ? selectedClient.name : "All clients"
    }`;
    const fullStopsRemoved = fileName.replaceAll(".", "");
    return fullStopsRemoved;
  };

  return (
    <>
      <PageTitleArea
        backButtonEnabled={false}
        title={selectedRecTypeName}
        description={`${selectedRecTypeName} Reconciliations`}
        borderBottom={false}
        props={
          <>
            <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_REC_UPLOAD_FILE])}>
              <Button
                size="md"
                onClick={() => {
                  modalInstance(MODALS.MANUAL_UPLOAD).show();
                }}
                disabled={false}
                color="primary-secondary"
              >
                <Text size="sm" weight="medium">
                  Upload a file
                </Text>
              </Button>
            </AuthWrapper>
            <span className="ml-16"></span>
            <ToolTip text="Download Dashboard" direction="left" size="large">
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  gridRef.current.api.exportDataAsCsv({ fileName: createFileName() }); //getParams())
                }}
              >
                <IconDownload className="btn-lg-svg" />
              </span>
            </ToolTip>
            <span className="ml-16 mr-16"></span>
          </>
        }
      />
      <ManageLayout mainContentToolbar={mainToolbar()} panelHeader={<></>} pageTitleArea={<></>} panelTabs={<></>}>
        <Table columnDefs={cols.overview} rowData={fundRows} ref={gridRef} colFlex={true} />
        <ManualFileUploadModal teamId={teamId} recType={selectedRecType} />
      </ManageLayout>
    </>
  );
});

export { RecTypeDash };
