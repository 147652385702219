import { useState, useRef, useContext, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Text, Button, R, C10, Toggle } from "@fundrecs/ui-library";
import { ManageLayout, TabsLayout } from "../layout/Layout.js";
import { CalendarPanel } from "./CalendarPanel";
import { STATUS } from "../../utils/enums.js";
import { ROW_FETCH_LIMIT, ALL_ROWS, fetchBatches, getLastPageNo } from "../../utils/rowBatchUtils.js";
import { UploadedFilesPanel } from "./UploadedFilesPanel.js";
import { Table } from "../ag-grid/Ag-grid";
import { useStore } from "../../store/Store.js";
import { getDateStringFromTimestamp } from "../../utils/dates";
import { updateRowPageUrl } from "../../utils/urls.js";
import { MatchPreviewPanel } from "./MatchPreviewPanel.js";
import { OpenRecToolbar } from "./reconciliationToolbars.js";
import { RunningMatchingRulesPlaceholder } from "./matchingRules/RunningMatchingRulesPlaceholder.js";
import { formatMatchedRowsForTable, formatUnmatchedRowsForTable } from "./recTableLogic.js";
import { PendingGlobalMappingsAlert } from "../globalMapping/PendingGlobalMappingsAlert.js";
import { ColumnViewEditor } from "./ColumnViewEditor.js";
import { RowCountsTable } from "./RowCountsTable.js";
import { DownloadTableButton } from "../reusable/DownloadTableButton.js";
import { ifNullUndefinedArray } from "../../utils/utils.js";
import { DifferenceCalculator } from "./DifferenceCalculator.js";
import { MatchingContext, MatchingDispatchContext, SET_AGGREGATORS } from "./matchingReducer.js";
import { RowBatchSelector } from "./toolbars/RowBatchSelector.js";

/**
 * View for the open rec page
 */
const OpenRecView = observer(
  ({
    selectedAccount,
    uploadedFiles = [],
    pollRecStatus,
    globalMappingsEnabledForAccount,
    globalMapping,
    subAccounts = [],
    rowGroupSchema = [],
    diffCalcHidden = false,
  }) => {
    const unmatchedText = "Unprocessed";
    const processedText = "Processed";
    const rowTableTabs = [
      { index: 0, text: unmatchedText },
      { index: 1, text: processedText },
    ];
    const { recStore, teamStore, recTypeStore, matchingRuleStore, tmoAggregatorStore, uiStore } = useStore();
    const unprocessedTableRef = useRef(null);
    const processedTableRef = useRef(null);
    const navigate = useNavigate();
    const rec = recStore.getSelectedRec();
    const [unprocessedRows, setUnprocessedRows] = useState({ columnDefs: [], rows: [] });
    const [processedRows, setProcessedRows] = useState({ columnDefs: [], rows: [] });
    const [matchPreview, setMatchPreview] = useState(null);
    const [matchPreviewRowData, setMatchPreviewRowData] = useState({ columns: [], rows: null });
    const [differencesRowData, setDifferencesRowData] = useState(null);
    const [initialised, setInitialised] = useState(false);
    const [selectedSubAccount, setSelectedSubAccount] = useState({});
    const [allRowsMatched, setAllRowsMatched] = useState({});
    const [matchingRuleId, setMatchingRuleId] = useState(null);
    const [rowCounts, setRowCounts] = useState([]);
    const [matchingRulesRunning, setMatchingRulesRunning] = useState(false);
    const [manualCategorizationOptions, setManualCategorizationOptions] = useState(null);
    const [submitRecDisabled, setSubmitRecDisabled] = useState(true);
    const [globalMappingEntries, setGlobalMappingEntries] = useState(null);
    const compactRowHeight = 28;
    const compactHeaderHeight = 32;
    const [rowHeight, setRowHeight] = useState(compactRowHeight);
    const [displayNumberOfRows, setDisplayNumberOfRows] = useState(ROW_FETCH_LIMIT);
    const [rowCountsForMatchingTable, setRowCountsForMatchingTable] = useState(null);
    const [unmatchedColumns, setUnmatchedColumns] = useState([]);
    const dispatch = useContext(MatchingDispatchContext);
    const selectedAggregators = useContext(MatchingContext)["aggregators"];

    const [searchParams, setSearchParams] = useSearchParams();
    const accountId = Number(searchParams.get("accId"));
    const recId = Number(searchParams.get("recId"));
    const recTypeId = Number(searchParams.get("recTypeId"));
    const teamId = Number(searchParams.get("teamId"));
    const subAccountUrlParam = Number(searchParams.get("subAccId"));
    const tabUrlParam = searchParams.get("tab") === processedText ? processedText : unmatchedText;

    let recType = recTypeStore.getSelectedRecType();
    recType = recType ? recType : recTypeStore.setSelectedRecTypeWithId(teamId, recTypeId);

    const recTypes = ifNullUndefinedArray(recTypeStore.getRecTypes());
    const matchingRules = matchingRuleStore.getMatchingRules();

    const [selectedTab, setSelectedTab] = useState(tabUrlParam === processedText ? rowTableTabs[1] : rowTableTabs[0]);
    const [existingParams, setExistingParams] = useState({
      teamId: teamId,
      recTypeId: recTypeId,
      accId: accountId,
      recId: recId,
      subAccId: subAccountUrlParam,
      tab: tabUrlParam,
    });

    const checkboxHeader = {
      field: "",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      headerCheckboxSelectionFilteredOnly: true,
      width: 50,
      maxWidth: 50,
    };

    const replaceUrl = (updatedParams) => {
      setExistingParams(updatedParams);
      updateRowPageUrl(navigate, updatedParams, true);
    };

    const changeTab = (note) => {
      if (selectedSubAccount != null && Object.keys(selectedSubAccount).length) {
        setSelectedTab(note);
        updateTable(note, selectedSubAccount, allRowsMatched);
      }
    };

    const selectSubAccount = async (subAccount, allRowsMatched) => {
      await matchingRuleStore.fetchMatchingRules(teamId, recTypeId, accountId, subAccount.id);
      setSelectedSubAccount(subAccount);
      updateTable(selectedTab, subAccount, allRowsMatched);
    };

    const selectSubAccountAndTab = async (subAccount, note) => {
      await matchingRuleStore.fetchMatchingRules(teamId, recTypeId, accountId, subAccount.id);
      setSelectedSubAccount(subAccount);
      setSelectedTab(note);
      updateTable(note, subAccount, allRowsMatched);
    };

    const updateTable = async (selectedTab, subAccount, allRowsMatched, recVersion = rec.version, numberOfRows = displayNumberOfRows) => {
      updateTableState();
      if (numberOfRows !== displayNumberOfRows) {
        setDisplayNumberOfRows(numberOfRows);
      }
      if (selectedTab.text === unmatchedText) {
        await populateUnmatchedTable(subAccount, allRowsMatched, recVersion, numberOfRows);
      } else if (selectedTab.text === processedText) {
        await getRowGroupsForSubAccount(subAccount, selectedTab.text, recVersion, numberOfRows);
      }
    };

    const updateTableState = () => {
      setProcessedRows({ ...processedRows, rows: null });
      setUnprocessedRows({ ...unprocessedRows, rows: null });
    };

    const populateUnmatchedTable = async (subAccount, allRowsMatched, recVersion, numberOfRows) => {
      const teamId = teamStore.getSelectedTeam().id;
      numberOfRows = numberOfRows === ALL_ROWS ? rowCountsForMatchingTable.unprocessedRowsCount : numberOfRows;
      let unmatchedRows = { rows: [], schema: [] };

      /*
      const responses = await fetchBatches(numberOfRows, (pageNo) => recStore.getUnmatchedRows(teamId, recId, subAccount.uuid, recVersion, pageNo));
      let rows = [];
      responses.forEach((resp) => (rows = [...rows, ...resp.rows]));
      unmatchedRows = { schema: responses && responses.length ? responses[0]["schema"] : [], rows: rows };
      */
      //Above commented logic sends the requests concurently, rather than the loop below which sends them consecutively
      for (let pageNo = 0; pageNo <= getLastPageNo(numberOfRows); pageNo++) {
        if (pageNo === 0) {
          unmatchedRows = await recStore.getUnmatchedRows(teamId, recId, subAccount.uuid, recVersion, pageNo);
        } else {
          const resp = await recStore.getUnmatchedRows(teamId, recId, subAccount.uuid, recVersion, pageNo);
          unmatchedRows.rows = [...unmatchedRows.rows, ...resp.rows];
        }
      }

      const tableData = formatUnmatchedRowsForTable(unmatchedRows);
      setUnprocessedRows({ columnDefs: [checkboxHeader].concat(tableData.columns), rows: tableData.rows });
      setAllRowsMatched({ ...allRowsMatched, [subAccount.uuid]: unmatchedRows.length < 1 });
    };

    /**
     * Gets all rowGroups grouped by subAccount and Note
     * Populates Array tableDataForSubAccounts with object {subAccountName: [Array of rowGroups ordered by classifier note]}
     * @param {*} classifierNotes
     */
    const getRowGroupsForSubAccount = async (subAccount = null, selectedTabText, recVersion, numberOfRows) => {
      if (subAccount != null && Object.keys(subAccount).length && selectedTabText === processedText && rowGroupSchema.length) {
        numberOfRows = numberOfRows === ALL_ROWS ? rowCountsForMatchingTable.rowGroupsCount : numberOfRows;
        let rowGroups = [];

        /*
        const responses = await fetchBatches(numberOfRows, (pageNo) => recStore.getRowGroupsForSubAccount(teamId, recId, subAccount.uuid, recVersion, pageNo));
        let rowGroups = [];
        responses.forEach((resp) => (rowGroups = [...rowGroups, ...resp]));
        */
        //Above commented logic sends the requests concurently, rather than the loop below which sends them consecutively
        for (let pageNo = 0; pageNo <= getLastPageNo(numberOfRows); pageNo++) {
          const resp = await recStore.getRowGroupsForSubAccount(teamId, recId, subAccount.uuid, recVersion, pageNo);
          rowGroups = [...rowGroups, ...resp];
        }

        const tableData = formatMatchedRowsForTable(rowGroupSchema, rowGroups, matchingRules, true);
        setProcessedRows({ columnDefs: [checkboxHeader].concat(tableData.columns), rows: tableData.rows });
      }
    };

    const getRowsForRowGroup = async (rowGroupId) => {
      const rows = await recStore.getRowsForRowGroup(teamId, rowGroupId);
      return rows;
    };

    const closePreview = () => {
      updateDifferences(selectedAggregators);
      deselectAllRows();
      setMatchPreview(null);
      setMatchPreviewRowData({ columns: [], rows: null });
      setMatchingRuleId(null);
      setManualCategorizationOptions({});
      setGlobalMappingEntries(null);
    };

    const updateMatchPreview = (previewType, rowGroupData) => {
      if (rowGroupData) {
        setMatchPreview(previewType);
        setMatchPreviewRowData(formatMatchedRowsForTable(rowGroupData.schema, rowGroupData.rowGroups, matchingRules));
        setGlobalMappingEntries(rowGroupData.globalMappingEntries);
      }
    };

    const removeMatchedRows = async () => {
      const selectedRows =
        processedTableRef && processedTableRef.current && processedTableRef.current.api ? processedTableRef.current.api.getSelectedRows() : [];
      if (selectedRows.length < 1) {
        uiStore.addNotification("error", "Please select at least 1 row");
      } else {
        const reqBody = selectedRows.map((it) => it.id);
        const recVersion = await recStore.removeMatch(teamStore.getSelectedTeam().id, recId, selectedSubAccount.uuid, rec.version, reqBody);
        if (recVersion) {
          const updatedAllRowsMatched = { ...allRowsMatched, [selectedSubAccount.uuid]: false };
          updateTable(selectedTab, selectedSubAccount, updatedAllRowsMatched, recVersion);
          setAllRowsMatched(updatedAllRowsMatched);
          refreshRowCounts();
          canSubmit();
        }
      }
    };

    const submitRec = async () => {
      const rec = recStore.getSelectedRec();
      recStore.submitRec(teamId, rec.id, rec.version);
    };

    const matchSuccessUpdateTable = (recVersion) => {
      closePreview();
      refreshRowCounts();
      updateTable(selectedTab, selectedSubAccount, allRowsMatched, recVersion);
      canSubmit();
    };

    const onClickExpandRows = async (params) => {
      let rows = [];
      if (params.data && params.data.id) {
        const resp = await getRowsForRowGroup(params.data.id);
        rows = formatUnmatchedRowsForTable(resp).rows;
      }
      return rows;
    };

    const canSubmit = async () => {
      const resp = await recStore.canSubmit(teamId, recId);
      if (resp.status === 200 && !matchingRulesRunning) {
        setSubmitRecDisabled(!resp.data);
      }
    };

    const recStatus = rec.fourEyes && rec.fourEyes.status ? rec.fourEyes.status : "";
    const processedRowButtonsDisabled = matchingRulesRunning || recStatus !== STATUS.DRAFT.status || selectedTab.index === 0;
    const submitMatchedRowsDisabled = matchingRulesRunning || !matchPreview || !matchPreviewRowData.rows.length;
    if (
      [STATUS.IMPORTING_DATA.status, STATUS.IN_PROGRESS.status, STATUS.REPORT_CLEAR.status, STATUS.REPORT_CREATE.status].includes(recStatus) &&
      !matchingRulesRunning
    ) {
      setMatchingRulesRunning(true);
      pollRecStatus();
    }

    const refreshRowCounts = async (subAccount = selectedSubAccount) => {
      const rowCountResponse = await recStore.fetchRowCounts(teamId, recId);
      const rowCountForTableResponse = await recStore.fetchRowCountForTable(teamId, recId, subAccount.id);
      setRowCounts(rowCountResponse);
      setRowCountsForMatchingTable(rowCountForTableResponse.length ? rowCountForTableResponse[0] : null);
    };

    const initaliseSubAccounts = async () => {
      setInitialised(true);
      if (!subAccounts.length) {
        const fundName = selectedAccount ? selectedAccount.name : "this fund";
        uiStore.addNotification("error", `No sub accounts enabled for ${fundName} and ${recType.name}`);
      } else {
        //Ensure the clients, accounts & sub accounts have been fetched
        const clientsAccountsPerRecType = recTypeStore.getClientsAccountsPerRecType();
        if (!clientsAccountsPerRecType.length) {
          await recTypeStore.fetchClientsAccountsPerRecType(teamId, recTypeId);
        }
        let allRowsMatched = {};
        subAccounts.forEach((subAccount) => {
          allRowsMatched[subAccount.uuid] = false;
        });
        const subAccountFromUrl = subAccounts.find((acc) => acc.id === subAccountUrlParam);
        const subAccount = subAccountFromUrl ? subAccountFromUrl : subAccounts[0];
        setAllRowsMatched(allRowsMatched);
        refreshRowCounts(subAccount);
        selectSubAccount(subAccount, allRowsMatched);
        canSubmit();

        const aggregatorResponse = await tmoAggregatorStore.getTmoAggregatorsForRecType(teamId, recTypeId);
        await recTypeStore.fetchTmosForRecType(teamId, recTypeId);
        if (aggregatorResponse.status === 200) {
          const aggregatorDefault = {};
          aggregatorResponse.data.forEach((agg) => {
            if (aggregatorDefault[agg.tmoId] === undefined) {
              aggregatorDefault[agg.tmoId] = agg;
            }
          });
          dispatch({ type: SET_AGGREGATORS, aggregators: aggregatorDefault });
        }
        const unmatchedRows = await recStore.getUnmatchedRows(teamStore.getSelectedTeam().id, recId, subAccount.uuid, rec.version);
        const columns = formatUnmatchedRowsForTable(unmatchedRows).columns;
        setUnmatchedColumns(columns);
      }
    };

    //RecTypes is the last step in the data loaded when the app is initilaised, and is required for the page.
    if (recTypes.length && rowGroupSchema.length && !initialised) {
      initaliseSubAccounts();
    }

    const getGridRef = () => {
      return selectedTab.text === unmatchedText ? unprocessedTableRef : processedTableRef;
    };

    const clearFilters = () => {
      const gridRef = getGridRef();
      gridRef.current.api.setFilterModel(null);
    };

    const toggleRowHeight = () => {
      const gridRef = getGridRef();
      const standardHeight = 40;
      const newHeight = rowHeight === compactRowHeight ? standardHeight : compactRowHeight;
      setRowHeight(newHeight);
      gridRef.current.api.forEachNode((rowNode) => {
        rowNode.setRowHeight(newHeight);
      });
      gridRef.current.api.onRowHeightChanged();
      gridRef.current.api.setHeaderHeight(rowHeight === compactRowHeight ? standardHeight : compactHeaderHeight);
    };

    const deselectAllRows = () => {
      const gridRef = getGridRef();
      const rows = gridRef.current.api.getSelectedNodes();
      rows.forEach((row) => {
        row.setSelected(false);
      });
    };

    const createFileName = () => {
      const fileName = `${recType.name} reconcilation (${selectedTab.text} rows)- ${selectedAccount.name}, ${
        selectedSubAccount.name
      } - ${getDateStringFromTimestamp(rec.startDate)}`;
      const fullStopsRemoved = fileName.replaceAll(".", "");
      return fullStopsRemoved;
    };

    const updateDifferences = async (aggregators) => {
      dispatch({ type: SET_AGGREGATORS, aggregators: aggregators });
      const fetchDiffCalc = async () => {
        const selectedRows = unprocessedTableRef.current.api.getSelectedRows().map((row) => row.id);
        if (selectedRows.length && selectedRows.length <= 20) {
          let data = {
            aggregatorSet: Object.values(aggregators).map((it) => it.id),
            categoryId: null,
            tagSet: [],
            toBePersisted: false,
            proceedAsRows: false,
            comment: null,
            rowIdSet: selectedRows,
          };
          let response = { status: null };
          response = await recStore.manuallyCreateRowGroup(teamStore.getSelectedTeam().id, rec.id, rec.version, data);

          if (response.status === 200 && response.data && unprocessedTableRef.current.api.getSelectedRows().length) {
            setDifferencesRowData(formatMatchedRowsForTable(response.data.schema, response.data.rowGroups, matchingRules));
          } else {
            setDifferencesRowData(null);
          }
        } else if (selectedRows.length === 0 || selectedRows.length > 20) {
          setDifferencesRowData(null);
        }
      };

      if (
        !diffCalcHidden &&
        unprocessedTableRef &&
        unprocessedTableRef.current &&
        unprocessedTableRef.current.api &&
        unprocessedTableRef.current.api.getSelectedRows().length
      ) {
        setDifferencesRowData({ columns: differencesRowData && differencesRowData.columns ? differencesRowData.columns : [], rows: null });
        setTimeout(async () => {
          await fetchDiffCalc();
        }, "200");
      }
    };

    const renderRowCount = useMemo(() => {
      return rowCountsForMatchingTable
        ? selectedTab.text === unmatchedText
          ? rowCountsForMatchingTable.unprocessedRowsCount
          : selectedTab.text === processedText
          ? rowCountsForMatchingTable.rowGroupsCount
          : ""
        : "";
    }, [selectedTab, rowCountsForMatchingTable]);

    return (
      <>
        <ManageLayout
          headerTabs={
            <TabsLayout
              tabs={rowTableTabs.map((note) => {
                return {
                  text: note.text,
                  onClick: () => {
                    replaceUrl({ ...existingParams, tab: note.text });
                    changeTab(note);
                  },
                };
              })}
              activeTab={selectedTab.index}
            />
          }
          rightToolbar={
            <OpenRecToolbar
              gridRef={selectedTab.text === unmatchedText ? unprocessedTableRef : processedTableRef}
              updateTable={() => updateTable(selectedTab, selectedSubAccount, allRowsMatched)}
              selectedTab={selectedTab}
              processedRowButtonsDisabled={processedRowButtonsDisabled}
              removeMatchedRows={removeMatchedRows}
              loading={false}
              submitRec={submitRec}
              submitRecDisabled={submitRecDisabled}
              matchingRulesRunning={matchingRulesRunning}
              setMatchingRulesRunning={setMatchingRulesRunning}
              rec={rec}
              pollRecStatus={pollRecStatus}
              updateMatchPreview={updateMatchPreview}
              setMatchingRuleId={setMatchingRuleId}
              setManualCategorizationOptions={setManualCategorizationOptions}
              matchingRules={matchingRuleStore.getMatchingRules()}
              subAccountId={selectedSubAccount.id}
              teamId={teamId}
            />
          }
          panelHeader={<></>}
          pageTitleArea={<></>}
          topToolbar={
            <>
              <RowCountsTable
                updateUrl={(subAccount, note) => {
                  replaceUrl({ ...existingParams, subAccId: subAccount.id, tab: note.text });
                  selectSubAccountAndTab(subAccount, note);
                  refreshRowCounts(subAccount);
                }}
                allSubAccounts={subAccounts}
                rowCounts={rowCounts}
                rowTableTabs={rowTableTabs}
              />

              <R props="mt-32">
                <C10 props="ml-32">
                  <Text size="lg" weight="bold">
                    {selectedSubAccount.name}
                  </Text>
                </C10>
              </R>
            </>
          }
        >
          <>
            {!matchingRulesRunning ? (
              ""
            ) : (
              <RunningMatchingRulesPlaceholder
                text={matchingRulesRunning && recStatus === STATUS.REPORT_CLEAR.status ? "Reopening reconciliation" : "Running matching rules"}
              />
            )}
            <PendingGlobalMappingsAlert
              teamId={teamId}
              recTypeId={recTypeId}
              accountId={accountId}
              globalMappingId={globalMappingsEnabledForAccount ? globalMapping.id : null}
            />
            <MatchPreviewPanel
              gridRef={selectedTab.text === unmatchedText ? unprocessedTableRef : processedTableRef}
              matchPreview={matchPreview}
              matchPreviewRowData={matchPreviewRowData.rows}
              closePreview={closePreview}
              submitMatchedRowsDisabled={submitMatchedRowsDisabled}
              loading={false}
              headers={matchPreviewRowData.columns}
              matchingRuleId={matchingRuleId}
              manualCategorizationOptions={manualCategorizationOptions}
              setMatchingRulesRunning={setMatchingRulesRunning}
              matchSuccessUpdateTable={matchSuccessUpdateTable}
              rec={rec}
              globalMappingEntries={globalMappingEntries}
            />
            <DifferenceCalculator
              show={!diffCalcHidden && selectedTab.text === unmatchedText && matchPreview === null && differencesRowData && differencesRowData.columns}
              differencesRowData={differencesRowData}
              updateDifferences={updateDifferences}
            />
            <div className="d-flex justify-content-between pt-16 pb-8 pr-0">
              <div>
                <ColumnViewEditor
                  gridRef={selectedTab.text === unmatchedText ? unprocessedTableRef : processedTableRef}
                  recTypeId={recTypeId}
                  teamId={teamId}
                  selectedTab={selectedTab.text}
                />
              </div>
              <RowBatchSelector
                rowCount={renderRowCount}
                displayNumberOfRows={displayNumberOfRows}
                fetchRows={async (option) => {
                  await updateTable(selectedTab, selectedSubAccount, allRowsMatched, rec.version, option);
                }}
              />
              <div className="d-flex justify-content-end" style={{ width: "30%", alignItems: "center" }}>
                <DownloadTableButton gridRef={selectedTab.text === unmatchedText ? unprocessedTableRef : processedTableRef} createFileName={createFileName} />
                <span className="mr-16"></span>
                <Button
                  size="sm"
                  onClick={() => {
                    clearFilters();
                  }}
                  disabled={false}
                  color={"tertiary"}
                >
                  <Text size="sm" weight="medium">
                    Clear Filters
                  </Text>
                </Button>
                <span className="mr-16"></span>
                <Toggle checked={rowHeight === compactRowHeight} onChange={toggleRowHeight} />
                <span className="pl-8" />
                <Text size="sm" weight="medium">
                  Compact mode
                </Text>
              </div>
            </div>
            <div
              className="pl-0 pr-0"
              style={{ display: selectedTab.text === processedText ? "none" : "initial" }}
              onMouseUp={(e) => {
                updateDifferences(selectedAggregators);
              }}
            >
              <Table
                columnDefs={unprocessedRows.columnDefs}
                rowData={unprocessedRows.rows}
                ref={unprocessedTableRef}
                rowSelection={"multiple"}
                rowClassRules={{
                  "manually-matched-row": (params) => Object.keys(params.data).includes("Matching Rule") && !params.data["Matching Rule"],
                }}
                autoSizeColumns={true}
                rowHeight={compactRowHeight}
                headerHeight={compactHeaderHeight}
              />
            </div>
            <div className="pl-0 pr-0" style={{ display: selectedTab.text === unmatchedText ? "none" : "initial" }}>
              <Table
                columnDefs={processedRows.columnDefs}
                rowData={processedRows.rows}
                ref={processedTableRef}
                rowSelection={"multiple"}
                rowClassRules={{
                  "manually-matched-row": (params) => Object.keys(params.data).includes("Matching Rule") && !params.data["Matching Rule"],
                }}
                autoSizeColumns={true}
                rowHeight={compactRowHeight}
                headerHeight={compactHeaderHeight}
                masterDetail={true}
                detailCellRendererParams={{
                  detailGridOptions: {
                    columnDefs: unmatchedColumns,
                  },
                  getDetailRowData: async (params) => {
                    const rows = await onClickExpandRows(params);
                    params.successCallback(rows);
                  },
                }}
              />
            </div>
          </>
        </ManageLayout>
        <CalendarPanel selectedDate={rec.startDate} selectedAccount={selectedAccount} />
        <UploadedFilesPanel
          uploadedFiles={uploadedFiles}
          accountName={selectedAccount ? selectedAccount.name : ""}
          date={getDateStringFromTimestamp(rec.startDate)}
          matchingRulesRunning={matchingRulesRunning}
        />
      </>
    );
  }
);

export { OpenRecView };
