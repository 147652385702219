import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useSearchParams, Link } from "react-router-dom";
import { Text, Button, IconAdd, IconInformation } from "@fundrecs/ui-library";
import { useStore } from "../../store/Store";
import { AuthWrapper } from "../AuthorizationWrapper";
import { AUTHORITIES } from "../../utils/enums";
import { PATH } from "../../utils/urls";
import { DisplayAccountAutoCompleteConfig } from "./DisplayAccountAutoCompleteConfig";
import { ConfigureAutoCompleteForAccount } from "./ConfigureAutoCompleteForAccount";

const AutoCompleteAccountSetup = observer(
  ({ recTypeId, recTypeAutocompleteEnabled, autoCompleteProp, autoCompleteSchedulerProp, accountId, accountVersionProp, recStartDateProp }) => {
    const { rolesStore } = useStore();
    const [searchParams, setSearchParams] = useSearchParams();
    const teamId = searchParams.get("teamId");
    const [editing, setEditing] = useState(false);
    const [autoComplete, setAutoComplete] = useState({ ...autoCompleteProp, scheduler: autoCompleteSchedulerProp });
    const [accountVersion, setAccountVersion] = useState(accountVersionProp);
    const [recStartDate, setRecStartDate] = useState(recStartDateProp);

    const updateAccountConfig = (accountVersion, acConfig = null, recStartDate) => {
      setAutoComplete(acConfig);
      setAccountVersion(accountVersion);
      setRecStartDate(recStartDate);
      setEditing(false);
    };

    return (
      <div className="pt-32 ml-32 mr-32 pb-32" style={{ borderTop: "1px solid #E6E8EB" }}>
        <Text size="sm" weight="bold">
          Autocomplete
        </Text>

        {recTypeAutocompleteEnabled && autoComplete !== null && Object.keys(autoComplete).length > 1 ? (
          <DisplayAccountAutoCompleteConfig
            recTypeAutocompleteEnabled={recTypeAutocompleteEnabled}
            autoComplete={autoComplete}
            editing={editing}
            setEdtiing={setEditing}
            teamId={teamId}
            accountId={accountId}
            accountVersion={accountVersion}
            recTypeId={recTypeId}
            updateAccountConfig={updateAccountConfig}
            recStartDate={recStartDate}
          />
        ) : (
          <div>
            <div className="d-flex justify-content-between">
              <div className="mt-16">
                <Text size="xs" weight="bold" variant="muted">
                  {`AUTOCOMPLETE SET UP: ${recTypeAutocompleteEnabled && autoComplete ? "YES" : "NO"}`}
                </Text>
              </div>
              <div>
                {recTypeAutocompleteEnabled ? (
                  <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_EDIT])}>
                    <Button
                      size="md"
                      onClick={() => {
                        setEditing(true);
                      }}
                      color="primary-secondary"
                      disabled={false}
                    >
                      <IconAdd className="btn-sm-svg" />
                      <Text size="sm" weight="bold">
                        Set up autocomplete
                      </Text>
                    </Button>
                  </AuthWrapper>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="d-flex justify-content-between">
              {editing ? (
                <div></div>
              ) : (
                <div className="mt-24 pt-8 pb-16 pl-16" style={{ background: "#FFF8F1", alignItems: "center" }}>
                  <IconInformation color="#D36133" className="btn-md-svg" />
                  <span className="ml-8"></span>
                  <Text weight="bold" size="sm" variant="warning">
                    Requires set up
                  </Text>
                  <div className="pl-16 ml-8">
                    <Text weight="regular" size="sm" variant="warning">
                      In order to enable autocomplete for a fund you must first set up the rule and schedule
                    </Text>
                  </div>
                </div>
              )}
              <div className="mt-32">
                <Link className="text-decoration-none" to={`${PATH.REC_TYPE_CONFIG}?teamId=${teamId}&recType=${recTypeId}&tab=0`}>
                  <Text size="xs" variant="link" weight="bold">
                    Go to autocomplete rule configuration
                  </Text>
                </Link>
              </div>
            </div>

            {editing ? (
              <ConfigureAutoCompleteForAccount
                recTypeId={recTypeId}
                accountId={accountId}
                accountVersion={accountVersion}
                updateAccountConfig={updateAccountConfig}
              />
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    );
  }
);

export { AutoCompleteAccountSetup };
