import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";
import {
  Text,
  Button,
  IconInformation,
  R,
  C1,
  C6,
  C8,
  IconDownArrow,
  DropdownList,
  DropdownListItem,
  DropdownItem,
  ItemBody,
  IconPencil,
  Checkbox,
  DatePicker,
  ToolTip,
  Radio,
} from "@fundrecs/ui-library";
import { useStore } from "../../store/Store";
import { AuthWrapper } from "../AuthorizationWrapper";
import { AUTHORITIES } from "../../utils/enums";
import styles from "./ScheduledTriggerView.module.scss";
import { stringifyDate, stringifyDateTimeISO8601 } from "../../utils/dates";

const renderNumber = (number) => {
  return number < 10 ? `0${number}` : number;
};

const ConfigureAutoCompleteForAccount = observer(({ recTypeId, accountId, accountVersion, updateAccountConfig }) => {
  const { rolesStore, autoCompleteStore, accountStore } = useStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId");
  const AUTOMATED_OPTION = "Automated (run after matching rules)";
  const SCHEDULE_OPTION = "Scheduled";
  const scheduleOptions = [AUTOMATED_OPTION, SCHEDULE_OPTION];
  const OLDEST_OPEN = "OLDEST_OPEN";
  const DEFAULT_SCHEDULE = { target: "OLDEST_OPEN", type: "daily", minsPast: null, hour: null, daysOfTheWeek: [0, 1, 2, 3, 4] };
  const targetOptions = { [OLDEST_OPEN]: "Oldest open reconciliation" };
  const [selectedAutoCompleteRule, setSelectedAutoCompleteRule] = useState(null);
  const [userDefinedSchedule, setUserDefinedSchedule] = useState(null);
  const [recStartDate, setRecStartDate] = useState(null);
  const [target, setTarget] = useState(OLDEST_OPEN);
  const [initialised, setInitialised] = useState(false);
  const [autoCompleteRules, setAutoCompleteRules] = useState([]);

  const fetchAutoCompleteRules = async () => {
    const resp = await autoCompleteStore.fetchAutoCompleteRules(teamId);
    if (resp.status === 200) {
      const acRulesForRecType = resp.data.find((it) => it.recTypeId === recTypeId);
      setAutoCompleteRules(acRulesForRecType && acRulesForRecType.autoCompleteList ? acRulesForRecType.autoCompleteList : []);
    }
  };

  const setTimeToLocalTime = (userDefinedSchedule) => {
    if (![null, undefined].includes(userDefinedSchedule.hour)) {
      const newDate = new Date();
      newDate.setHours(userDefinedSchedule.hour);
      userDefinedSchedule.hour = newDate.getUTCHours();
    }

    if (![null, undefined].includes(userDefinedSchedule.minsPast)) {
      const newDate = new Date();
      newDate.setMinutes(userDefinedSchedule.minsPast);
      userDefinedSchedule.minsPast = newDate.getUTCMinutes();
    }
  };

  const saveAutoMatchConfig = async () => {
    let updatedAccountVersion = accountVersion;
    let scheduler = null;
    let updatedRecStartDate = null;
    const createConfig = await accountStore.setAutoCompleteRule(teamId, accountId, selectedAutoCompleteRule.id, accountVersion, recTypeId);
    if (createConfig.status === 200) {
      updatedAccountVersion = createConfig.data;
      if (userDefinedSchedule) {
        setTimeToLocalTime(userDefinedSchedule);
        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);
        userDefinedSchedule.startDate = stringifyDateTimeISO8601(today);
        const createSchedule = await accountStore.setAutoCompleteRuleSchedule(
          teamId,
          accountId,
          selectedAutoCompleteRule.id,
          createConfig.data,
          recTypeId,
          userDefinedSchedule
        );
        if (createSchedule.status === 200) {
          updatedAccountVersion = createSchedule.data;
          scheduler = userDefinedSchedule;

          const setRecStartDate = await accountStore.setRecStartDateForAutocomplete(
            teamId,
            accountId,
            selectedAutoCompleteRule.id,
            updatedAccountVersion,
            recTypeId,
            { date: recStartDate ? stringifyDateTimeISO8601(recStartDate) : null }
          );
          if (setRecStartDate.status === 200) {
            updatedAccountVersion = setRecStartDate.data;
            updatedRecStartDate = recStartDate;
          }
        }
      }
      updateAccountConfig(updatedAccountVersion, { name: selectedAutoCompleteRule.name, scheduler: scheduler }, updatedRecStartDate);
    }
  };

  if (!initialised) {
    setInitialised(true);
    fetchAutoCompleteRules();
  }

  return (
    <>
      <div>
        <div className="d-flex">
          <C6>
            <div className="mb-16">
              <Text size="xs" weight="bold" style={{ width: "80%" }}>
                Select autocomplete rule
              </Text>
            </div>
            <div className="dropdown mr-8" style={{ width: "90%" }}>
              <div data-bs-toggle="dropdown" className="dropdown-button-container ellipsize-text">
                {selectedAutoCompleteRule ? selectedAutoCompleteRule.name : "Select rule"}
                <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
              </div>
              <DropdownList>
                {autoCompleteRules.map((rule, index) => {
                  return (
                    <DropdownListItem
                      onClick={() => {
                        setSelectedAutoCompleteRule(rule);
                      }}
                      key={index + 1}
                    >
                      <DropdownItem active={null} index={0}>
                        <ItemBody title={rule.name}>{rule.name}</ItemBody>
                      </DropdownItem>
                    </DropdownListItem>
                  );
                })}
              </DropdownList>
            </div>
          </C6>
          <div>
            <div className="mb-16">
              <Text size="xs" weight="bold">
                Schedule autocomplete rule
              </Text>
            </div>
            <div className="dropdown mr-8" style={{ width: "300px" }}>
              <div data-bs-toggle="dropdown" className="dropdown-button-container ellipsize-text">
                <span>{userDefinedSchedule ? SCHEDULE_OPTION : AUTOMATED_OPTION}</span>
                <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
              </div>
              <DropdownList>
                {scheduleOptions.map((text, index) => {
                  return (
                    <DropdownListItem
                      onClick={() => {
                        setUserDefinedSchedule(text === SCHEDULE_OPTION ? DEFAULT_SCHEDULE : null);
                      }}
                      key={index + 1}
                    >
                      <DropdownItem active={null} index={0}>
                        <ItemBody title={text}>{text}</ItemBody>
                      </DropdownItem>
                    </DropdownListItem>
                  );
                })}
              </DropdownList>
            </div>
          </div>
        </div>
        {userDefinedSchedule ? (
          <>
            <TitleDivider title="TARGETED RECONCILIATIONS" description="Define the targeted period of reconciliations autocomplete should run on" />
            <div className="d-flex">
              <div className="mr-24">
                <div className="mb-16">
                  <Text size="xs" weight="bold">
                    Autocomplete target
                  </Text>
                </div>
                <div className="dropdown mr-8" style={{ width: "300px" }}>
                  <div data-bs-toggle="dropdown" className="dropdown-button-container ellipsize-text">
                    <span>{target ? targetOptions[target] : "Please select"}</span>
                    <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
                  </div>
                  <DropdownList>
                    {[Object.entries(targetOptions)].map((keyValueArray, index) => {
                      return (
                        <DropdownListItem
                          onClick={() => {
                            setTarget(keyValueArray[index][0]);
                          }}
                          key={index + 1}
                        >
                          <DropdownItem active={null} index={0}>
                            <ItemBody title={keyValueArray[index][1]}>{keyValueArray[index][1]}</ItemBody>
                          </DropdownItem>
                        </DropdownListItem>
                      );
                    })}
                  </DropdownList>
                </div>
              </div>
              <div>
                <div className="d-flex mb-16">
                  <Text size="xs" weight="bold" element="div">
                    Include reconciliations starting from
                  </Text>
                  <span className="ml-8">
                    <ToolTip text={"Select a start date for the targeted period of reconciliations"} size="medium">
                      <IconInformation color="#9ca2b0" className="btn-sm-svg" />
                    </ToolTip>
                  </span>
                </div>
                <div className="d-flex">
                  <Radio
                    onClick={() => {
                      setRecStartDate(null);
                    }}
                    checked={recStartDate === null}
                  />
                  <span className="ml-8 pt-8 mr-16">
                    <Text size="xs" weight="bold">
                      Anytime
                    </Text>
                  </span>
                  <Radio
                    onClick={() => {
                      setRecStartDate(new Date());
                    }}
                    checked={recStartDate !== null}
                  />
                  <span className="ml-8 pt-8 mr-16">
                    <Text size="xs" weight="bold">
                      Selected date
                    </Text>
                  </span>
                </div>
              </div>
              {![undefined, null].includes(recStartDate) ? (
                <div className="ml-32">
                  <div className="mb-16">
                    <Text size="xs" weight="bold" element="div">
                      Target start date
                    </Text>
                  </div>
                  <DatePicker
                    date={recStartDate}
                    onDateChange={(date) => {
                      setRecStartDate(date);
                    }}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      {userDefinedSchedule ? (
        <>
          <TitleDivider title="SCHEDULE SETTINGS" description="Configure the time and days autocomplete should run on" />
          <ScheduleSetup userDefinedSchedule={userDefinedSchedule} setUserDefinedSchedule={setUserDefinedSchedule} recStartDate={recStartDate} />
        </>
      ) : (
        ""
      )}

      <div className="mt-16">
        <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_EDIT])}>
          <Button
            size="md"
            onClick={saveAutoMatchConfig}
            disabled={
              !target || !selectedAutoCompleteRule || (userDefinedSchedule && (userDefinedSchedule.hour === null || userDefinedSchedule.minsPast === null))
            }
          >
            <Text size="sm">Save autocomplete settings</Text>
          </Button>
        </AuthWrapper>
      </div>
    </>
  );
});

const TitleDivider = ({ title, description, toolTipDirection = "right" }) => {
  return (
    <div className="d-flex mt-32" style={{ letterSpacing: "1px" }}>
      <ToolTip text={description} direction={toolTipDirection} size="medium">
        <IconInformation color="#9ca2b0" className="btn-sm-svg" />
      </ToolTip>
      <div className="ml-16 text-xs text-bold light-text-muted" style={{ letterSpacing: "1px" }}>
        {title}
      </div>
      <div
        class="ml-16"
        style={{
          width: "100%",
          marginTop: "20px",
          borderTop: "1px solid #CDD0D7",
          transform: "translateY(-50%)",
          paddingTop: "25px",
        }}
      ></div>
    </div>
  );
};

const ScheduleSetup = ({ userDefinedSchedule, setUserDefinedSchedule, recStartDate }) => {
  const monToFri = [
    { value: 0, label: "Mon", day: "Monday" },
    { value: 1, label: "Tue", day: "Tuesday" },
    { value: 2, label: "Wed", day: "Wednesday" },
    { value: 3, label: "Thu", day: "Thursday" },
    { value: 4, label: "Fri", day: "Friday" },
  ];
  const [selectedDays, setSelectedDays] = useState(monToFri);

  let hours = [];
  let minutes = [];
  for (let i = 0; i < 60; i++) {
    if (i % 5 === 0) {
      minutes.push(i < 10 ? `0${i}` : i);
    }

    if (i < 24) {
      hours.push(i < 10 ? `0${i}` : i);
    }
  }

  const daysOfWeek = [...monToFri, { value: 5, label: "Sat", day: "Saturday" }, { value: 6, label: "Sun", day: "Sunday" }];

  const handleDaysChange = (e) => {
    const { value, checked } = e.target;
    const day = daysOfWeek.find((it) => it.value === parseInt(value));
    if (day) {
      const updatedSelection = checked ? [...selectedDays, day] : selectedDays.filter((x) => x.value !== parseInt(value));
      setSelectedDays(updatedSelection);
      setUserDefinedSchedule({ ...userDefinedSchedule, daysOfTheWeek: updatedSelection.map((it) => it.value) });
    }
  };

  const generateDaysString = () => {
    let strValue = "";
    if (selectedDays.length === 7) {
      strValue = "day";
    } else {
      selectedDays.sort((a, b) => (a.value < b.value ? -1 : a.value > b.value ? 1 : 0));
      selectedDays.forEach((day, index) => {
        strValue += `${day.day}${index < selectedDays.length - 1 ? ", " : ""}`;
      });
    }
    return strValue;
  };

  return (
    <div>
      <div className="d-flex">
        <div>
          <div className="mb-8">
            <Text size="xs" weight="bold">
              Run time
            </Text>
          </div>
          <div className="dropdown mr-8" style={{ width: "60px" }}>
            <div data-bs-toggle="dropdown" className="dropdown-button-container ellipsize-text">
              {userDefinedSchedule.hour === null ? "Hour" : renderNumber(userDefinedSchedule.hour)}
            </div>
            <ul className="dropdown-menu dropdown-sizing" aria-labelledby="dropdownMenuLink" style={{ height: "200px", overflowX: "scroll" }}>
              {hours.map((text, index) => {
                return (
                  <DropdownListItem
                    onClick={() => {
                      setUserDefinedSchedule({ ...userDefinedSchedule, hour: Number(text) });
                    }}
                    key={index + 1}
                  >
                    <DropdownItem active={null} index={0}>
                      <ItemBody title={text}>{text}</ItemBody>
                    </DropdownItem>
                  </DropdownListItem>
                );
              })}
            </ul>
          </div>
        </div>

        <div className="pr-8 mt-24" style={{ paddingTop: "6px", fontSize: "large" }}>
          :
        </div>
        <div style={{ marginTop: "25px" }}>
          <div className="dropdown mr-8" style={{ width: "60px" }}>
            <div data-bs-toggle="dropdown" className="dropdown-button-container ellipsize-text">
              {userDefinedSchedule.minsPast === null ? "min" : renderNumber(userDefinedSchedule.minsPast)}
            </div>
            <ul className="dropdown-menu dropdown-sizing" aria-labelledby="dropdownMenuLink" style={{ height: "200px", overflowX: "scroll" }}>
              {minutes.map((text, index) => {
                return (
                  <DropdownListItem
                    onClick={() => {
                      setUserDefinedSchedule({ ...userDefinedSchedule, minsPast: Number(text) });
                    }}
                    key={index + 1}
                  >
                    <DropdownItem active={null} index={0}>
                      <ItemBody title={text}>{text}</ItemBody>
                    </DropdownItem>
                  </DropdownListItem>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <div className="mb-8">
          <Text element="div" size="xs" weight="bold">
            On days
          </Text>
        </div>
      </div>
      <div className="d-flex">
        {daysOfWeek.map((day, index) => {
          const selected = selectedDays.find((selected) => selected.value === day.value);
          return (
            <div key={Math.random()} className={[styles.roundCheckbox, "pr-12"].join(" ")}>
              <Checkbox id={day.value} onClick={handleDaysChange} value={day.value} checked={selected} />
              <label htmlFor={day.value}>
                <Text size="xs" weight="medium" element="span" variant={selected ? "link" : "muted"}>
                  {day.label}
                </Text>
              </label>
            </div>
          );
        })}
      </div>
      <div className="mt-16 mb-12">
        <Text size="sm" weight="medium" variant="secondary">
          {`Summary: Autocomplete every 
            ${generateDaysString()} ${
            userDefinedSchedule.hour !== null && userDefinedSchedule.minsPast !== null
              ? `at ${renderNumber(userDefinedSchedule.hour)}:${renderNumber(userDefinedSchedule.minsPast)} starting today `
              : ""
          }
            and targeting the oldest open reconciliation${![undefined, null].includes(recStartDate) ? ` from ${stringifyDate(recStartDate)}` : ""} `}
        </Text>
      </div>
    </div>
  );
};

export { ConfigureAutoCompleteForAccount };
