import React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, Link } from "react-router-dom";
import { ToolTip, IconRightArrow, modalInstance } from "@fundrecs/ui-library";
import { ListItem } from "../layout/ListItem";
import { Badge } from "../layout/Layout";
import { PATH } from "../../utils/urls";
import { useStore } from "../../store/Store";
import { MODALS } from "../../utils/enums";

const UploadedFilesList = observer(({ uploadedFiles }) => {
  const { teamStore } = useStore();
  const navigate = useNavigate();
  const uploadedFileBadgeStyle = (tmoName) => {
    let style = { backgroundColor: "#CDD0D7", color: "#6A7388", fontWeight: "600" };
    if (tmoName === "Admin") {
      style.backgroundColor = "#E8F6EE";
      style.color = "#15A251";
    } else if (tmoName === "Broker") {
      style.backgroundColor = "#E9EFFF";
      style.color = "#1E5EFF";
    }
    return style;
  };

  return (
    <>
      {uploadedFiles.map((file) => {
        return (
          <ListItem
            leftContent={<Badge style={uploadedFileBadgeStyle(file.tmoName)} text={file.tmoName} />}
            title={file.fileName}
            rightContent={
              <>
                <ToolTip text="View Import Summary" direction="bottom" size="medium">
                  <Link className="text-decoration-none" to={`${PATH.UPLOADED_FILE}?teamId=${teamStore.getSelectedTeam().id}&fileId=${file.fileId}`}>
                    <IconRightArrow
                      style={{ color: "#1E5EFF", marginRight: "8px", cursor: "pointer" }}
                      className="btn-lg-svg"
                      onClick={() => {
                        modalInstance(MODALS.UPLOADED_FILES).hide();
                      }}
                    />
                  </Link>
                </ToolTip>
              </>
            }
          />
        );
      })}
    </>
  );
});

export { UploadedFilesList };
