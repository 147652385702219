import { useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { Text, IconDownArrow, HoverDropdown } from "@fundrecs/ui-library";
import { ALL_ROWS, ROW_FETCH_LIMIT } from "../../../utils/rowBatchUtils";

const RowBatchSelector = observer(({ rowCount, displayNumberOfRows, fetchRows }) => {
  const buttonStyle = {
    fontSize: "1rem",
    padding: "0.3rem",
    paddingTop: "0.2rem",
    paddingBottom: "0.3rem",
    lineHeight: "initial",
    color: "#6A7388",
    backgroundColor: "#fff",
    border: "1px solid #CDD0D7",
    verticalAlign: "middle",
    maxHeight: "30px",
    paddingRight: "8px",
    paddingLeft: "8px",
    borderRadius: "0.25rem",
    borderLeft: "1px solid #CDD0D7",
  };

  const [disabled, setDisabled] = useState(false);

  const generateRowLimitDropdown = useMemo(() => {
    const limits = [ROW_FETCH_LIMIT * 2, ROW_FETCH_LIMIT * 5, ROW_FETCH_LIMIT * 8, ROW_FETCH_LIMIT * 12, ROW_FETCH_LIMIT * 16, ROW_FETCH_LIMIT * 25];
    let options = rowCount > ROW_FETCH_LIMIT ? [ROW_FETCH_LIMIT] : [];
    limits.forEach((limit) => {
      if (rowCount >= limit) {
        options.push(limit);
      }
    });
    if (rowCount > 0) {
      options.push(ALL_ROWS);
    }
    return options;
  }, [rowCount]);

  const selectOption = async (option) => {
    setDisabled(true);
    await fetchRows(option);
    setDisabled(false);
  };

  return (
    <div>
      <Text size="sm" weight="normal">
        {`Showing ${displayNumberOfRows !== ALL_ROWS ? "up to" : ""}`}
      </Text>
      <span className="ml-8"></span>
      <button data-bs-toggle="dropdown" type="button" style={buttonStyle} disabled={disabled}>
        <Text size="sm" weight="medium">
          {displayNumberOfRows}
        </Text>
        <span className="mr-8"></span>
        <IconDownArrow className="btn-lg-svg" />
      </button>
      <span className="ml-8"></span>
      <Text size="sm" weight="normal">
        {`of ${rowCount}`}
      </Text>
      <HoverDropdown>
        {generateRowLimitDropdown.map((option) => {
          return (
            <li
              onClick={() => {
                selectOption(option);
              }}
              className="hover-dropdown-item"
            >
              <span className="align-self-center">
                <Text variant="primary" weight="regular" size="sm">
                  {option}
                </Text>
              </span>
            </li>
          );
        })}
      </HoverDropdown>
    </div>
  );
});

export { RowBatchSelector };
