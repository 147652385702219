import { useState, useRef } from "react";
import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";
import { modalInstance, Text, Button, R } from "@fundrecs/ui-library";
import { AUTHORITIES, MODALS } from "../../utils/enums";
import { Table } from "../ag-grid/Ag-grid";
import { renderHeaderWithCount, nodeChildren } from "../ag-grid/utils";
import { useStore } from "../../store/Store";
import { VerticalMenu } from "../reusable/VerticalMenu/VerticalMenu";
import { EnableSubAccount } from "./EnableSubAccount";
import { CreateSubAccountFundAlias } from "./CreateSubAccountFundAlias";
import { ExpandCollapseButton } from "../ag-grid/expandCollapse";
import { AuthWrapper, isUserAuthorized } from "../AuthorizationWrapper";

const SubAccountTable = observer(({ account }) => {
  const gridRef = useRef(null);
  const { accountStore, recTypeStore, subAccountStore, rolesStore } = useStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId");
  const [initialised, setInitialised] = useState(false);
  const [recTypeRows, setRecTypeRows] = useState(null);
  const [selectedRecType, setSelectedRecType] = useState(null);
  const tableWidth = document.getElementsByTagName("body")[0].clientWidth * 0.9;
  const fundNameHeaderWidth = tableWidth * 0.2;
  const fundIdentifierHeaderWidth = tableWidth * 0.45;
  const [enabledRecTypes, setEnabledRecTypes] = useState([]);
  const [subAccounts, setSubAccounts] = useState([]);
  const [enabledSubAccounts, setEnabledSubAccounts] = useState(null);
  const [selectedSubAccount, setSelectedSubAccount] = useState(null);

  const cols = [
    {
      resizable: false,
      headerName: "Reconciliation Type",
      field: "recTypeName",
      width: fundNameHeaderWidth,
      maxWidth: fundNameHeaderWidth,
      rowGroup: true,
      hide: true,
      valueFormatter: (params) => {
        return renderHeaderWithCount(params, "recTypeName", "aliasName");
      },
      suppressColumnsToolPanel: true,
    },
    {
      resizable: false,
      headerName: "Sub Account",
      field: "subAccountName",
      width: fundNameHeaderWidth,
      maxWidth: fundNameHeaderWidth,
      rowGroup: true,
      hide: true,
      valueFormatter: (params) => {
        return renderHeaderWithCount(params, "subAccountName", "aliasName");
      },
      suppressColumnsToolPanel: true,
    },
    {
      resizable: false,
      headerName: "Fund Alias",
      field: "aliasName",
      width: fundIdentifierHeaderWidth,
      maxWidth: tableWidth * 0.25,
      suppressColumnsToolPanel: true,
    },
    {
      resizable: false,
      headerName: "Actions",
      field: "actions",
      width: fundNameHeaderWidth,
      maxWidth: fundNameHeaderWidth,
      cellRenderer: (params) => {
        return params && !params.data && params.node.field === "recTypeName" ? (
          <AuthWrapper
            teamId={teamId}
            allRequired={rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_SUB_ACCOUNT_ENABLE, AUTHORITIES.RECS_ACCOUNT_SUB_ACCOUNT_REMOVE])}
          >
            <Button
              size="sm"
              color="primary-secondary"
              onClick={() => {
                const data = params.node.allLeafChildren[0].data;
                setSelectedRecType({ id: data.recTypeId, name: data.recTypeName, version: data.recTypeVersion });
                modalInstance(MODALS.ENABLE_SUB_ACCOUNT).show();
              }}
            >
              <Text size="sm">Enable sub account</Text>
            </Button>
          </AuthWrapper>
        ) : params && !params.data && params.node.field === "subAccountName" ? (
          <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_FUND_ALIAS_ADD, AUTHORITIES.RECS_FUND_ALIAS_REMOVE])}>
            <Button
              size="sm"
              color="primary-secondary"
              onClick={() => {
                const data = nodeChildren(params)[0].data;
                setSelectedRecType({ id: data.recTypeId, name: data.recTypeName, version: data.recTypeVersion });
                setSelectedSubAccount({
                  id: data.subAccountId,
                  name: data.subAccountName,
                  recTypeId: data.recTypeId,
                });
                modalInstance(MODALS.ADD_SUBACCOUNT_FUND_ALIAS).show();
              }}
            >
              <Text size="sm">Add alias</Text>
            </Button>
          </AuthWrapper>
        ) : params && params.data && params.data.aliasId ? (
          <div style={{ width: "25px" }}>
            <VerticalMenu teamId={teamId} data={{ aliasId: params.data.aliasId }} getMenuItemsFromRowData={getMenuItems} onItemClick={onItemClick} />
          </div>
        ) : (
          ""
        );
      },
      suppressColumnsToolPanel: true,
    },
  ];

  const onItemClick = (option, optionData) => {
    const options = {
      removeFundAlias: () => {
        removeFundAlias(optionData);
      },
    };
    options[option]();
  };

  const getMenuItems = ({ teamId, data }) => {
    const removeAliasEnabled = isUserAuthorized({ teamId: teamId, allRequired: rolesStore.getActions([AUTHORITIES.RECS_FUND_ALIAS_REMOVE]) });
    const menuItems = [{ key: "removeFundAlias", label: "Remove Alias", disabled: !removeAliasEnabled, visible: removeAliasEnabled, optionData: {} }];
    return menuItems.map((menuItem) => {
      menuItem.optionData = data;
      return menuItem;
    });
  };

  const removeFundAlias = async (data) => {
    const response = await recTypeStore.deleteAlias(teamId, selectedSubAccount.recTypeId, data.aliasId, "ACCOUNT_AND_SUB_ACCOUNT");
    if (response.status === 200) {
      refreshRows();
    }
  };

  const refreshRows = async () => {
    let rows = [];
    let tableData = [];
    let subAccounts = [];
    let enabledRecTypes = [];
    let subAccountsPerRecType = {};
    if (account) {
      enabledRecTypes = await accountStore.fetchEnabledRecTypesForAccount(teamId, account.id);
      subAccounts = await subAccountStore.getAllSubAccountsForTeam(teamId);
      tableData = await accountStore.fetchRecTypesSubAccountsAliases(teamId, account.id);
    }
    tableData.forEach((recType) => {
      const recTypeInfo = {
        teamId: teamId,
        recTypeId: recType.id,
        accountId: account.id,
        accountVersion: account.version,
        recTypeVersion: recType.version,
        recTypeName: recType.name,
      };
      if (!recType.subAccounts || !recType.subAccounts.length) {
        rows.push(recTypeInfo);
      }
      subAccountsPerRecType[recType.id] = [];
      recType.subAccounts.forEach((subAccount) => {
        if (!subAccountsPerRecType[recType.id].find((it) => it.id === subAccount.id)) {
          subAccountsPerRecType[recType.id].push({ id: subAccount.id, version: subAccount.version, name: subAccount.name, recTypeId: recType.id });
        }
        const subAccountInfo = { ...recTypeInfo, subAccountId: subAccount.id, subAccountVersion: subAccount.version, subAccountName: subAccount.name };
        if (!subAccount.aliasList || !subAccount.aliasList.length) {
          rows.push(subAccountInfo);
        }
        subAccount.aliasList.forEach((alias) => {
          rows.push({ ...subAccountInfo, aliasId: alias.id, aliasName: alias.alias });
        });
      });
    });
    setEnabledSubAccounts(subAccountsPerRecType);
    setEnabledRecTypes(enabledRecTypes);
    setSubAccounts(subAccounts);
    setRecTypeRows(rows);
  };

  const deleteIdentifier = async (optionData) => {
    const response = await recTypeStore.deleteAlias(teamId, optionData.recTypeId, optionData.fundIdentifierId, "ACCOUNT");
    if (response.status === 200) {
      refreshRows();
    }
  };

  if (!initialised && account) {
    refreshRows();
    setInitialised(true);
  }

  return (
    <>
      <div style={{ width: "300px", margin: "8px 0px 8px 0px" }}>
        <ExpandCollapseButton gridRef={gridRef} />
      </div>
      <R>
        <Table columnDefs={cols} rowData={recTypeRows} ref={gridRef} colFlex={true} width={`${tableWidth}px`} />
      </R>
      {!account ? (
        ""
      ) : (
        <>
          <EnableSubAccount
            recTypes={enabledRecTypes}
            subAccounts={subAccounts}
            updateTable={refreshRows}
            accountId={account.id}
            accountVersion={account.version}
            selectedRecType={selectedRecType}
            setSelectedRecType={setSelectedRecType}
          />
          <CreateSubAccountFundAlias
            recTypeName={selectedRecType ? selectedRecType.name : ""}
            selectedSubAccount={selectedSubAccount}
            setSelectedSubAccount={setSelectedSubAccount}
            enabledSubAccounts={selectedRecType ? enabledSubAccounts[selectedRecType.id] : []}
            accountId={account.id}
            updateTable={refreshRows}
          />
        </>
      )}
    </>
  );
});

export { SubAccountTable };
