import { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Text,
  R,
  C4,
  C8,
  Button,
  modalInstance,
  IconAdd,
  OneProductGlobalMappingsIcon,
  OneProductMatchingRulesDataIcon,
  IconSuccessMessage,
  IconUpArrow,
  IconDownArrow,
  VerticalMenuDropdown,
  VerticalMenuListItem,
  ToolTip,
  IconDownload,
} from "@fundrecs/ui-library";
import { AUTHORITIES, MODALS, REC_TYPE_CONFIG_VIEWS } from "../../utils/enums.js";
import { ManageLayout, TabsLayout, PageTitleArea } from "../layout/Layout.js";
import { useStore } from "../../store/Store.js";
import { PATH } from "../../utils/urls.js";
import "./recTypeDash.css";
import { CreateDataVerificationRule } from "./DataVerification/CreateDataVerificationRule.js";
import { DataVerificationRuleBuilder } from "./DataVerification/DataVerificationRuleBuilder.js";
import { DvRulesList } from "./DataVerification/DvRulesList.js";
import { MatchingRulesList } from "./MatchingRules/MatchingRulesList.js";
import { RecTypeConfig } from "./recTypeConfig/RecTypeConfig.js";
import { AuthWrapper } from "../AuthorizationWrapper.js";
import { ReactComponent as ForwardIcon } from "../../icons/forward.svg";
import { ifNullUndefinedArray } from "../../utils/utils.js";
import { RecTypeSettingsPanel } from "./recTypeConfig/RecTypeSettingsPanel.js";
import { getDayAndTimeString } from "../../utils/dates.js";
import { buildFunctionString } from "../rules/FunctionEditor.js";

const RecTypeConfigLayout = observer(({}) => {
  const {
    teamStore,
    recTypeStore,
    dataVerificationStore,
    comparatorFunctionStore,
    globalMappingStore,
    rolesStore,
    autoCompleteStore,
    manualMatchingCriteriaStore,
    carryForwardStore,
  } = useStore();
  let [activeTab, setActiveTab] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [fundRowsInitialised, setFundRowsInitialised] = useState(false);
  const [showDvRuleBuilder, setShowDvRuleBuilder] = useState(false);
  const tmoColumns = recTypeStore.getRecTypeColumnMap();
  const tmos = recTypeStore.getRecTypeTmos();
  const teamId = searchParams.get("teamId") ? Number(searchParams.get("teamId")) : null;
  const [recTypeId, setRecTypeId] = useState(searchParams.get("recType") ? Number(searchParams.get("recType")) : null);
  const navigate = useNavigate();
  const selectedRecType = recTypeStore.getSelectedRecType();
  const selectedRecTypeName = selectedRecType ? selectedRecType.name : "";
  const recTypes = ifNullUndefinedArray(recTypeStore.getRecTypes());
  const [currentView, setCurrentView] = useState(REC_TYPE_CONFIG_VIEWS.AUTO_COMPLETE_VIEW);

  const updateUrl = (tab, replace, urlRecTypeId = recTypeId) => {
    setActiveTab(tab);
    navigate(`${PATH.REC_TYPE_CONFIG}?teamId=${teamStore.getSelectedTeam().id}&recType=${urlRecTypeId}&tab=${tab}`, { replace: replace });
  };

  useEffect(() => {
    //Populate fund Table with data for all clients
    if ((!fundRowsInitialised && recTypes.length) || recTypeId !== Number(searchParams.get("recType"))) {
      const teamId = searchParams.get("teamId");
      const recTypeId = searchParams.get("recType") ? Number(searchParams.get("recType")) : null;
      dataVerificationStore.fetchDvRulesForRecType(teamId, recTypeId);
      globalMappingStore.fetchGlobalMappingByRecTypeId(teamId, recTypeId);
      autoCompleteStore.fetchAutoCompleteRules(teamId);
      carryForwardStore.fetchCarryForwardRules(teamId, recTypeId);
      manualMatchingCriteriaStore.fetchAllForRecType(teamId, recTypeId);
      recTypeStore.setSelectedRecTypeWithId(teamId, recTypeId);
      recTypeStore.fetchTmosForRecType(teamId, recTypeId);
      comparatorFunctionStore.fetchFunctions(teamId);
      const tab = Number(searchParams.get("tab"));
      updateUrl(tab, true, recTypeId);
      setRecTypeId(recTypeId);
      setFundRowsInitialised(true);
    }
  });

  const mainTabs = () => {
    return (
      <TabsLayout
        tabs={[
          {
            text: `Configuration`,
            onClick: () => {
              updateUrl(0, true);
            },
          },
          {
            text: `Data Verification`,
            onClick: () => {
              updateUrl(1, true);
              dataVerificationStore.fetchDvRulesForRecType(searchParams.get("teamId"), recTypeId);
            },
          },
          {
            text: `Matching Rules`,
            onClick: () => {
              updateUrl(2, true);
            },
          },
        ]}
        activeTab={activeTab}
      />
    );
  };

  const generateDvRulesDescription = () => {
    const recTypeColumnMap = recTypeStore.getRecTypeColumnMap();
    const groupTmoColumns = recTypeStore.getGroupTmoColumns();

    const dvRules = dataVerificationStore.getDvRulesForRecType();
    const tmos = recTypeStore.getRecTypeTmos();
    const comparatorFunctions = comparatorFunctionStore.getFunctions();
    let str = `Data Verification Rules for ${selectedRecTypeName}\nDownloaded on ${getDayAndTimeString(
      new Date()
    )}\nData verification rules will run in order across all enabled funds and sub accounts. Verification rules will run before matching rules.\n\n\n`;

    dvRules.forEach((rule) => {
      const tmo = tmos.find((it) => it.id === rule.tmoId);
      const columns = tmo && tmo.id ? recTypeColumnMap[tmo.id] : groupTmoColumns;
      const tmoName = tmo ? tmo.name : "";
      str += `---------------------------------------------------------------------------------------------------------------\n\n`;

      str += `Name: ${rule.name}\nStatus: ${rule.state.status}\n`;
      str += tmo ? `\nWhen any ${tmo.name} data is available\n` : "\n";

      rule.conditions.map((condition, index) => {
        const comparatorFunction = comparatorFunctions.find((it) => it.function === condition.function);
        str += comparatorFunction ? buildFunctionString(index, tmo, condition, comparatorFunction.description, columns, tmoName) : "";
      });
      str += `\nThen tag as ${rule.actions && rule.actions.length === 1 && rule.actions[0]["noteText"] ? rule.actions[0]["noteText"] : ""}`;
      str += `\n\nCreated at ${getDayAndTimeString(new Date(rule.createdBy.timeStamp))}`;
      str += `\n\n`;
    });
    return str;
  };

  const downloadDataVerificationRules = () => {
    const fileData = generateDvRulesDescription();
    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = `Data Verification Rules for ${selectedRecTypeName} - ${getDayAndTimeString(new Date())}.txt`;
    link.href = url;
    link.click();
  };

  const mainToolbar = () => {
    return activeTab === 1 ? (
      <R props="pt-24 pb-16 pr-16">
        <C8 props="pl-32"></C8>
        <C4 props="text-right">
          <ToolTip text="Download Data Verification Rules" direction="left" size="large">
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                downloadDataVerificationRules();
              }}
            >
              <IconDownload className="btn-lg-svg" />
            </span>
          </ToolTip>
          <span className="ml-32"></span>
          <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_DATA_VERIFICATION_CREATE])}>
            <Button
              onClick={() => {
                modalInstance(MODALS.CREATE_DATA_VERIFICATION_RULE).show();
              }}
            >
              <IconAdd className="btn-md-svg" />
              <Text size="sm">New data verification rule</Text>
            </Button>
          </AuthWrapper>
        </C4>
      </R>
    ) : activeTab === 2 ? (
      <R props="pt-24 pb-16 pr-16">
        <C8 props="pl-32"></C8>
        <C4 props="text-right">
          <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_MATCHING_RULE_CREATE])}>
            <Button
              onClick={() => {
                modalInstance(MODALS.CREATE_MATCHING_RULE).show();
              }}
            >
              <IconAdd className="btn-md-svg" />
              <Text size="sm">New matching rule</Text>
            </Button>
          </AuthWrapper>
        </C4>
      </R>
    ) : (
      ""
    );
  };

  const ExpandingSideMenuItem = ({ title, listItems = [] }) => {
    const [open, setOpen] = useState(true);

    return (
      <div>
        <div
          className="d-flex justify-content-between pt-8 pb-8 pl-8 pr-8"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <Text size="sm" weight="bold">
            {title}
          </Text>
          {open ? <IconUpArrow /> : <IconDownArrow className="btn-lg-svg" />}
        </div>
        {open
          ? listItems.map((item, index) => {
              return (
                <div
                  className="pt-8 pb-8 pl-8 pr-8"
                  onClick={item.onClick}
                  style={{
                    cursor: "pointer",
                    background: currentView === item.view ? "#F7F8F9" : "white",
                    margin: "2px",
                    borderRadius: "8px",
                  }}
                >
                  {item.icon}
                  <span className="ml-8"></span>
                  <Text size="xs">{item.title}</Text>
                </div>
              );
            })
          : ""}
      </div>
    );
  };

  const sideMenuItems = [
    <ExpandingSideMenuItem
      title="Post-processing"
      listItems={[
        {
          title: "Auto complete",
          onClick: () => {
            setCurrentView(REC_TYPE_CONFIG_VIEWS.AUTO_COMPLETE_VIEW);
          },
          icon: <IconSuccessMessage className="btn-xs-svg" />,
          view: REC_TYPE_CONFIG_VIEWS.AUTO_COMPLETE_VIEW,
        },
        {
          title: "Manual matching",
          onClick: () => {
            setCurrentView(REC_TYPE_CONFIG_VIEWS.MANUAL_MATCHING_VIEW);
          },
          icon: <OneProductMatchingRulesDataIcon className="btn-xs-svg" />,
          view: REC_TYPE_CONFIG_VIEWS.MANUAL_MATCHING_VIEW,
        },
        {
          title: "Carry forward",
          onClick: () => {
            setCurrentView(REC_TYPE_CONFIG_VIEWS.CARRY_FORWARD_VIEW);
          },
          icon: <ForwardIcon className="btn-xs-svg" />,
          view: REC_TYPE_CONFIG_VIEWS.CARRY_FORWARD_VIEW,
        },
        {
          title: "Global mappings",
          onClick: () => {
            setCurrentView(REC_TYPE_CONFIG_VIEWS.GLOBAL_MAPPING_VIEW);
          },
          icon: <OneProductGlobalMappingsIcon className="btn-xs-svg" currentcolor="green" />,
          view: REC_TYPE_CONFIG_VIEWS.GLOBAL_MAPPING_VIEW,
        },
      ]}
    />,
  ];

  return (
    <>
      <PageTitleArea
        backButtonEnabled={false}
        title={selectedRecTypeName}
        description={`${selectedRecTypeName} Reconciliations`}
        borderBottom={false}
        props={
          <VerticalMenuDropdown>
            <VerticalMenuListItem
              text="Settings"
              onClick={() => {
                modalInstance(MODALS.REC_TYPE_SETTINGS).show();
              }}
            />
          </VerticalMenuDropdown>
        }
      />
      <RecTypeSettingsPanel teamId={teamId} recType={selectedRecType} />
      <ManageLayout listHeader={activeTab === 0} listItems={sideMenuItems} headerTabs={mainTabs()} mainContentToolbar={mainToolbar()} panelHeader={<></>}>
        {activeTab === 0 && <RecTypeConfig recTypeId={recTypeId} currentView={currentView} />}
        {activeTab === 1 && <DvRulesList />}
        {activeTab === 2 && <MatchingRulesList />}
      </ManageLayout>
      <CreateDataVerificationRule
        recTypeName={selectedRecTypeName}
        showDvRuleBuilder={() => {
          setShowDvRuleBuilder(true);
        }}
      />
      <DataVerificationRuleBuilder
        tmos={tmos}
        tmoColumns={tmoColumns}
        show={showDvRuleBuilder}
        toggleShow={() => {
          setShowDvRuleBuilder(!showDvRuleBuilder);
        }}
      />
    </>
  );
});

export { RecTypeConfigLayout };
