import { useState, useRef, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Text, Button, IconAdd, modalInstance } from "@fundrecs/ui-library";
import { PageTitleArea, ManageLayout } from "../layout/Layout";
import { Table } from "../ag-grid/Ag-grid";
import { useStore } from "../../store/Store";
import { PATH } from "../../utils/urls";
import { VerticalMenu } from "../reusable/VerticalMenu/VerticalMenu";
import { AUTHORITIES, MODALS } from "../../utils/enums";
import { AccountSetup } from "./AccountSetup/AccountSetup";
import { AuthWrapper } from "../AuthorizationWrapper";
import { DownloadTableButton } from "../reusable/DownloadTableButton";

const Accounts = observer(({}) => {
  const { recTypeStore, teamStore, accountStore, rolesStore } = useStore();
  const gridRef = useRef(null);
  const navigate = useNavigate();
  const [initialised, setInitialised] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId") ? Number(searchParams.get("teamId")) : teamStore.getSelectedTeam().id;
  const [rows, setRows] = useState(null);
  const accounts = accountStore.getAccountsForSelectedTeam();
  if (accounts !== null && rows === null) {
    const rows = accounts.map((account) => {
      return { id: account.id, client: account.clientName, name: account.name };
    });
    setRows(rows);
  }

  useEffect(() => {
    if (!initialised && teamId) {
      accountStore.fetchAccountsForTeam(teamId);
      navigate(`${PATH.FUND}?teamId=${teamId}`, { replace: true });
      recTypeStore.setSelectedRecType(null);
      setInitialised(true);
    }
  });

  const getMenuItems = ({ teamId, data }) => {
    return [
      { key: "fundDetails", label: "Fund Overview", visible: true, link: `${PATH.FUND_DETAILS}?teamId=${teamId}&accountId=${data.id}&tab=0` },
      { key: "recTypes", label: "Reconciliation Types", link: `${PATH.FUND_DETAILS}?teamId=${teamId}&accountId=${data.id}&tab=1` },
      { key: "fundIdentifiers", label: "Fund Identifiers", link: `${PATH.FUND_DETAILS}?teamId=${teamId}&accountId=${data.id}&tab=2` },
      { key: "subAccounts", label: "Sub Accounts", link: `${PATH.FUND_DETAILS}?teamId=${teamId}&accountId=${data.id}&tab=3` },
      { key: "matchingRules", label: "Matching Rules", link: `${PATH.FUND_DETAILS}?teamId=${teamId}&accountId=${data.id}&tab=4` },
    ];
  };

  const cols = [
    { headerName: "Client", field: "client", width: 150 },
    { headerName: "Fund Name", field: "name" },
    {
      field: "expandRow",
      width: 50,
      maxWidth: 50,
      cellRenderer: (params) => {
        return <VerticalMenu teamId={teamStore.getSelectedTeam().id} data={params.data} getMenuItemsFromRowData={getMenuItems} />;
      },
    },
  ];

  const createFileName = () => {
    const fileName = `All funds for ${teamStore.getSelectedTeam().name}`;
    const fullStopsRemoved = fileName.replaceAll(".", "");
    return fullStopsRemoved;
  };

  return (
    <>
      <PageTitleArea
        backButtonEnabled={false}
        title="Funds"
        description={<div className="text-medium pb-4">All funds belonging to this team</div>}
        borderBottom={false}
        rightToolbar=""
        props={
          <>
            <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_CREATE])}>
              <Button
                size="md"
                onClick={() => {
                  modalInstance(MODALS.ACCOUNT_SETUP).show();
                }}
                disabled={false}
                color="primary"
              >
                <IconAdd className="btn-sm-svg" />
                <Text size="sm" weight="medium">
                  New fund
                </Text>
              </Button>
            </AuthWrapper>
            <span className="ml-16"></span>
            <DownloadTableButton gridRef={gridRef} createFileName={createFileName} />
          </>
        }
      />
      <ManageLayout>
        <div className="mt-32"></div>
        <Table rowSelection="single" columnDefs={cols} rowData={rows} ref={gridRef} colFlex={true} />
      </ManageLayout>
      <AccountSetup />
    </>
  );
});

export { Accounts };
