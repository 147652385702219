import { useState, useRef } from "react";
import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";
import { R, IconDownload, ToolTip } from "@fundrecs/ui-library";
import { ManageLayout, PageTitleArea } from "../layout/Layout";
import { useStore } from "../../store/Store.js";
import { Table } from "../ag-grid/Ag-grid";
import { getDateForAuditTrail, getDayAndTimeString } from "../../utils/dates";

const RecAuditTrail = observer(({}) => {
  const gridRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [auditArray, setAuditArray] = useState(null);
  const [initialised, setInitialised] = useState(false);
  const { recStore } = useStore();
  const teamId = searchParams.get("teamId") ? searchParams.get("teamId") : null;
  const recId = searchParams.get("recId") ? searchParams.get("recId") : null;
  const accountName = searchParams.get("account") ? searchParams.get("account") : null;
  const recTypeName = searchParams.get("recType") ? searchParams.get("recType") : null;
  const date = searchParams.get("date") ? searchParams.get("date") : null;

  const fetchRecAudit = async () => {
    const response = await recStore.fetchRecAudit(teamId, recId);
    if (response.status === 200) {
      setAuditArray(response.data);
    }
  };

  const processAuditArray = (auditArray) => {
    return auditArray && auditArray.length
      ? auditArray.map((auditEntry) => {
          return { entry: auditEntry.fieldName, date: getDateForAuditTrail(auditEntry.date), user: auditEntry.email };
        })
      : [];
  };

  if (!initialised && teamId && recId) {
    fetchRecAudit();
    setInitialised(true);
  }

  const createFileName = () => {
    const fileName = `${recTypeName} audit trail - ${accountName} - ${date}`;
    return fileName.replaceAll(".", "");
  };

  return (
    <>
      <PageTitleArea
        title="Audit Trail"
        description={"Log of activity"}
        borderBottom={false}
        props={
          <ToolTip text="Download Dashboard" direction="left" size="large">
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                gridRef.current.api.exportDataAsCsv({ fileName: createFileName() });
              }}
            >
              <IconDownload className="btn-lg-svg" />
            </span>
          </ToolTip>
        }
      />
      <ManageLayout headerTabs={""} mainContentToolbar={""} panelHeader={<></>} pageTitleArea={<></>} panelTabs={<></>}>
        <>
          <R props="mt-16">
            <Table
              columnDefs={[
                { headerName: "Action", field: "entry" },
                {
                  headerName: "Date",
                  field: "date",
                  sort: "desc",
                  comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
                    return valueA === valueB ? 0 : valueA > valueB ? 1 : -1;
                  },
                  cellRenderer: (params) => {
                    return getDayAndTimeString(params.data.date);
                  },
                },
                { headerName: "User", field: "user" },
              ]}
              rowData={auditArray === null ? null : processAuditArray(auditArray)}
              ref={gridRef}
              sizeToFit={true}
            />
          </R>
        </>
      </ManageLayout>
    </>
  );
});

export { RecAuditTrail };
