/**
 * These helper functions are used to transform the data to/from formats used by the front-end and back-end
 * Each parameter for the function inputs created in the Data Verification and Matching rules has its own type.
 * The UI types are defined as "text", "number", "date", "notes" - these are used for FE data verification
 *
 * Each parameter in a function has its type defined in the 'input' Array. These are used to map to the ui inputs described above.
 * - These values can be "STRING", "NUMBER", "DATE", "NOTES"
 *
 * Once the value has been entered by the user, the type must be updated to reflect whether the user has selected a column or manually entered a value
 * - The values for these are "column", "constant"
 *
 *  (f)formatInputParameter - Sets the input from the user to be sent to the back-end (ensures type is either "column" or "constant")
 *  (f)getInputType - Maps the type defined by the back-end to the type required for use by the frontend
 *  (f)generateDropdownData & (f)generateInitialDropdownOptions both format column data for dropdowns used in FlexiInput
 */

const DATA_TYPES = { STRING: "text", NUMBER: "number", DATE: "date", NOTES: "notes", BOOLEAN: "boolean" };
const MANUAL_INPUT_VALUE = { uiValue: "Value", dataValue: "Value" };
const COLUMN = "column";
const CONSTANT = "constant";
const CONDITION = "condition";
const ACTION = "action";
const VERIFICATION = "VERIFICATION";
const TAG = "TAG";
const CATEGORY = "CATEGORY";
const NOTES = "notes";

const getDisplayName = (name, columns) => {
  const column = columns.find((it) => it.name === name);
  return column ? column.displayName : name;
};

/**
 * Creates a map of format [tmo-name: [...columns], tmo-name: columns]
 * to be used in dropdowns
 * @param {Object} columnDataByTmo Format: [tmo-name: [...columns], tmo-name: columns]
 * @returns
 */
const generateDropdownData = (columnDataByTmo, type = CONDITION) => {
  let returnData = {};
  Object.keys(columnDataByTmo).forEach((key) => {
    returnData[key] = columnDataByTmo[key].map((it) => {
      return createDropdownItem(type, it);
    });
  });
  return returnData;
};

const createDropdownItem = (type, data) => {
  let value = {};
  if (type === ACTION) {
    value = { uiValue: data.text, dataValue: data, dataType: "text" };
  } else if (type === CONDITION) {
    value = { uiValue: data.displayName, dataValue: data.name, dataType: DATA_TYPES[data.columnType] };
  }
  return value;
};

/**
 * Creates an Array of Strings for initialDropdownOptions
 * If manualInput, include MANUAL_INPUT_VALUE
 * @param {*} manualInput
 * @param {*} stringOptions
 * @returns
 */
const generateInitialDropdownOptions = (manualInput, stringOptions) => {
  const options = stringOptions.map((str) => {
    return { uiValue: str, dataValue: str };
  });
  return manualInput ? [...options, MANUAL_INPUT_VALUE] : options;
};

/**
 * Set the values for the parameter to be sent to the backend
 * @param {*} paramToUpdate
 * @param {*} value
 * @param {*} type
 * @param {*} tmoType
 */
const formatInputParameter = (paramToUpdate, value, type, tmoType) => {
  if (type === NOTES) {
    paramToUpdate["type"] = type;
    paramToUpdate["value"] = JSON.stringify(value.length ? value.map((it) => it.id) : []);
    paramToUpdate[NOTES] = value;
  } else {
    paramToUpdate["name"] = value;
    paramToUpdate["value"] = value;
    paramToUpdate["type"] = type === COLUMN ? type : CONSTANT;
    paramToUpdate["tmoType"] = tmoType;
  }
};

/**
 * The data type must be transformed using DATA_TYPES
 * The keys for DATA_TYPE correspond to the data types used in the BE
 * The UI elemenets use the values from DATA_TYPES
 * @param {*} conditionInputs Array of the types for the inputs
 * @param {*} type The type defined by the user input from FlexiInput
 * @param {*} paramIndex Index position of the parameter
 * @returns
 */
const getInputType = (conditionInputs, type, paramIndex) => {
  let returnType = type;
  if ([COLUMN, CONSTANT].includes(type)) {
    const dataTypeFromBackEnd = conditionInputs[paramIndex];
    returnType = dataTypeFromBackEnd && DATA_TYPES[dataTypeFromBackEnd] ? DATA_TYPES[dataTypeFromBackEnd] : returnType;
  }
  return returnType;
};

const getDisplayValue = (param, dropdownData) => {
  let value = "";
  //If this is a column, get the corresponding displayName from dropdownData
  if (param.type === COLUMN && Object.keys(dropdownData).includes(param.tmoType)) {
    const columnValue = dropdownData[param.tmoType].find((it) => param.value === it.name);
    value = columnValue ? columnValue.displayName : param.value;
  } else {
    value = param.value;
  }
  return value;
};

const formatFilterToEdit = (comparatorFunctions, originalFilter, tmoName) => {
  const filterFunction = comparatorFunctions.find((it) => it.function === originalFilter.function);
  let formattedFilter = { ...filterFunction };
  formattedFilter.params = originalFilter.params;
  formattedFilter.params.forEach((it) => {
    it.tmoType = tmoName;
    if (it.type === "column") {
      it.value = it.name;
    }
  });
  return formattedFilter;
};

const formatConditionsToEdit = (filterOperations, comparatorFunctions, tmos) => {
  let ret = {};
  Object.entries(filterOperations).forEach((keyValue) => {
    const tmo = tmos.find((it) => it.id === Number(keyValue[0]));
    let filters = [];
    keyValue[1].forEach((filter) => {
      filters.push(formatFilterToEdit(comparatorFunctions, filter, tmo.name));
    });
    ret[tmo.id] = filters;
  });
  return ret;
};

const formatAggregatorsToEdit = (aggregatorSet) => {
  let ret = {};
  aggregatorSet.forEach((agg) => {
    ret[agg.tmoId] = agg.id;
  });
  return ret;
};

const formatConditionsActionsToEdit = (tagSteps, comparatorFunctions) => {
  let conditionsActions = [];
  tagSteps.forEach((tagStep) => {
    const conditions = tagStep.conditions.map((condition) => {
      return formatFilterToEdit(comparatorFunctions, condition, "test");
    });
    const actions = [tagStep.note];
    conditionsActions.push({ conditions: conditions, actions: actions });
  });

  return conditionsActions;
};

const formatMatchingRuleToEdit = (matchingRule, ruleToEdit, comparatorFunctions, tmos) => {
  if (!ruleToEdit || !ruleToEdit.filterStep || !ruleToEdit.filterStep.filterOperations || !ruleToEdit.filterStep.aggregatorSet) {
    return matchingRule;
  }

  const conditions = formatConditionsToEdit(ruleToEdit.filterStep.filterOperations, comparatorFunctions, tmos);
  const tmoIds = Object.keys(conditions);
  let formattedRule = {
    conditions: conditions,
    groupings: ruleToEdit.filterStep.groupBy && ruleToEdit.filterStep.groupBy.groupings ? ruleToEdit.filterStep.groupBy.groupings : [],
    globalMappingId: ruleToEdit.filterStep.globalMapping ? ruleToEdit.filterStep.globalMapping.id : null,
    proceedAsRows: ruleToEdit.filterStep.proceedAsRows,
    tmoAggregatorIdMap: formatAggregatorsToEdit(ruleToEdit.filterStep.aggregatorSet),
    tmoId: tmoIds.length !== 1 ? null : Number(tmoIds[0]),
    conditionsActions: formatConditionsActionsToEdit(ruleToEdit.tagSteps, comparatorFunctions),
  };
  return { ...matchingRule, ...formattedRule };
};

export {
  generateDropdownData,
  generateInitialDropdownOptions,
  formatInputParameter,
  getInputType,
  getDisplayValue,
  getDisplayName,
  formatMatchingRuleToEdit,
  MANUAL_INPUT_VALUE,
  COLUMN,
  CONSTANT,
  DATA_TYPES,
  ACTION,
  CONDITION,
  VERIFICATION,
  TAG,
  CATEGORY,
  NOTES,
};
