import { useState, useRef } from "react";
import { observer } from "mobx-react-lite";
import { modalInstance, Text, Button, R } from "@fundrecs/ui-library";
import { AUTHORITIES, MODALS } from "../../utils/enums";
import { Table } from "../ag-grid/Ag-grid";
import { renderHeaderWithCount } from "../ag-grid/utils";
import { useStore } from "../../store/Store";
import { VerticalMenu } from "../reusable/VerticalMenu/VerticalMenu";
import { ExpandCollapseButton } from "../ag-grid/expandCollapse";
import { CreateFundIdentifier } from "./CreateFundIdentifier";
import { AuthWrapper, isUserAuthorized } from "../AuthorizationWrapper";

const FundIdentifiersTable = observer(({ teamId, account }) => {
  const gridRef = useRef(null);
  const { accountStore, recTypeStore, rolesStore } = useStore();
  const [initialised, setInitialised] = useState(false);
  const [recTypeRows, setRecTypeRows] = useState(null);
  const [selectedRecType, setSelectedRecType] = useState(null);
  const tableWidth = document.getElementsByTagName("body")[0].clientWidth * 0.9;
  const fundNameHeaderWidth = tableWidth * 0.2;
  const fundIdentifierHeaderWidth = tableWidth * 0.45;
  const [enabledRecTypes, setEnabledRecTypes] = useState([]);

  const cols = [
    {
      resizable: false,
      headerName: "Reconciliation Type",
      field: "recTypeName",
      width: fundNameHeaderWidth,
      maxWidth: fundNameHeaderWidth,
      rowGroup: true,
      hide: true,
      suppressColumnsToolPanel: true,
      valueFormatter: (params) => {
        return renderHeaderWithCount(params, "recTypeName", "fundIdentifierName");
      },
    },
    {
      resizable: false,
      headerName: "Fund Identifier",
      field: "fundIdentifierName",
      width: fundIdentifierHeaderWidth,
      maxWidth: fundIdentifierHeaderWidth,
      suppressColumnsToolPanel: true,
    },
    {
      suppressColumnsToolPanel: true,
      resizable: false,
      headerName: "Actions",
      field: "actions",
      width: tableWidth * 0.3,
      maxWidth: tableWidth * 0.3,
      cellRenderer: (params) => {
        return params && !params.data && params.node.field === "recTypeName" ? (
          <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_FUND_IDENTIFIER_ADD, AUTHORITIES.RECS_FUND_IDENTIFIER_REMOVE])}>
            <Button
              size="sm"
              color="primary-secondary"
              onClick={() => {
                const data = params.node.allLeafChildren[0].data;
                setSelectedRecType({ id: data.recTypeId, name: data.recTypeName, version: data.recTypeVersion });
                modalInstance(MODALS.CREATE_FUND_IDENTIFIER).show();
              }}
            >
              <Text size="sm">Add fund identifier</Text>
            </Button>
          </AuthWrapper>
        ) : params && params.data && params.data.fundIdentifierId ? (
          <div style={{ width: "25px" }}>
            <VerticalMenu
              teamId={teamId}
              data={{ fundIdentifierId: params.data.fundIdentifierId, recTypeId: params.data.recTypeId }}
              getMenuItemsFromRowData={getMenuItems}
              onItemClick={onItemClick}
            />
          </div>
        ) : (
          ""
        );
      },
    },
  ];

  const refreshRows = async () => {
    let enabledRecTypes = [];
    let tableData = [];
    let rows = [];
    if (account) {
      enabledRecTypes = await accountStore.fetchEnabledRecTypesForAccount(teamId, account.id);
      tableData = await accountStore.fetchRecTypesWithAliases(teamId, account.id);
    }
    tableData.forEach((recType) => {
      const recTypeInfo = {
        teamId: teamId,
        recTypeId: recType.id,
        accountId: account.id,
        accountVersion: account.version,
        recTypeVersion: recType.version,
        recTypeName: recType.name,
      };
      if (!recType.aliasList || !recType.aliasList.length) {
        rows.push(recTypeInfo);
      }
      recType.aliasList.forEach((fundIdentifier) => {
        rows.push({ ...recTypeInfo, fundIdentifierId: fundIdentifier.id, fundIdentifierName: fundIdentifier.alias });
      });
    });

    setEnabledRecTypes(enabledRecTypes);
    setRecTypeRows(rows);
  };

  const deleteIdentifier = async (optionData) => {
    const response = await recTypeStore.deleteAlias(teamId, optionData.recTypeId, optionData.fundIdentifierId, "ACCOUNT");
    if (response.status === 200) {
      refreshRows();
    }
  };

  const onItemClick = (option, optionData) => {
    const options = {
      deleteIdentifier: () => {
        deleteIdentifier(optionData);
      },
    };
    options[option]();
  };

  const getMenuItems = ({ teamId, data }) => {
    const removeIdentifierEnabled = isUserAuthorized({ teamId: teamId, allRequired: rolesStore.getActions([AUTHORITIES.RECS_FUND_IDENTIFIER_REMOVE]) });
    const menuItems = [
      { key: "deleteIdentifier", label: "Delete Identifier", disabled: !removeIdentifierEnabled, visible: removeIdentifierEnabled, optionData: {} },
    ];
    return menuItems.map((menuItem) => {
      menuItem.optionData = { teamId: teamId, fundIdentifierId: data.fundIdentifierId, recTypeId: data.recTypeId };
      return menuItem;
    });
  };

  if (!initialised && account) {
    refreshRows();
    setInitialised(true);
  }

  return (
    <>
      <div style={{ width: "300px", margin: "8px 0px 8px 0px" }}>
        <ExpandCollapseButton gridRef={gridRef} />
      </div>
      <R>
        <Table columnDefs={cols} rowData={recTypeRows} ref={gridRef} colFlex={true} width={`${tableWidth}px`} />
      </R>
      <CreateFundIdentifier
        selectedRecType={selectedRecType}
        setSelectedRecType={setSelectedRecType}
        recTypes={enabledRecTypes}
        accountId={account ? account.id : null}
        updateTable={refreshRows}
      />
    </>
  );
});

export { FundIdentifiersTable };
