import { useState } from "react";
import { Text, Button, modalInstance, IconCheck, IconCloseRemove, IconFileImportSetup } from "@fundrecs/ui-library";
import { AuthWrapper } from "../AuthorizationWrapper.js";
import { AUTHORITIES, MODALS } from "../../utils/enums.js";
import { Spinner } from "../layout/Spinner.js";
import { MatchingOptions } from "./matchingRules/MatchingOptions.js";
import { useStore } from "../../store/Store.js";
import { AddNotePanel } from "./AddNotePanel.js";

const CompletedRecToolbar = ({ teamId, reopenDisabled = false, reopenRec }) => {
  const { rolesStore } = useStore();

  return (
    <div className="mb-16">
      <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_REC_REOPEN])}>
        <Button
          size="md"
          onClick={() => {
            reopenRec();
          }}
          disabled={reopenDisabled}
          color={"success-primary"}
        >
          <Text size="sm" weight="medium">
            Reopen
          </Text>
        </Button>
      </AuthWrapper>
    </div>
  );
};

const PendingRecToolbar = ({ teamId, rejectRec, rejectApproveDisabled, approveRec }) => {
  const { rolesStore } = useStore();
  return (
    <div className="mb-16">
      <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_REC_REJECT])}>
        <Button
          size="md"
          onClick={() => {
            rejectRec();
          }}
          disabled={false}
          color={"danger"}
        >
          <Text size="sm" weight="medium">
            Reject
          </Text>
        </Button>
      </AuthWrapper>
      <span className="pr-8"></span>
      <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_REC_APPROVE])}>
        <Button
          size="md"
          onClick={() => {
            approveRec();
          }}
          disabled={rejectApproveDisabled}
          color={"success-primary"}
        >
          <IconCheck className={`btn-md-svg`} />
          <Text size="sm" weight="medium">
            Approve
          </Text>
        </Button>
      </AuthWrapper>
    </div>
  );
};

const OpenRecToolbar = ({
  selectedTab,
  processedRowButtonsDisabled,
  removeMatchedRows,
  loading,
  submitRec,
  submitRecDisabled,
  matchingRulesRunning,
  setMatchingRulesRunning,
  rec,
  pollRecStatus,
  updateMatchPreview,
  setMatchingRuleId,
  setManualCategorizationOptions,
  matchingRules,
  subAccountId,
  teamId,
  gridRef,
  updateTable,
}) => {
  const { rolesStore, uiStore } = useStore();
  const [selectedRow, setSelectedRow] = useState(null);

  return (
    <>
      {selectedTab.index === 0 ? (
        <>
          <MatchingOptions
            gridRef={gridRef}
            matchingRulesRunning={matchingRulesRunning}
            setMatchingRulesRunning={setMatchingRulesRunning}
            rec={rec}
            pollRecStatus={pollRecStatus}
            updateMatchPreview={updateMatchPreview}
            setMatchingRuleId={setMatchingRuleId}
            setManualCategorizationOptions={setManualCategorizationOptions}
            matchingRules={matchingRules}
            subAccountId={subAccountId}
          />
          <span className="pr-8"></span>
        </>
      ) : (
        <>
          <AuthWrapper
            teamId={teamId}
            oneRequired={rolesStore.getActions([
              AUTHORITIES.RECS_ROW_GROUP_ADD_NOTE,
              AUTHORITIES.RECS_ROW_GROUP_REMOVE_NOTE,
              AUTHORITIES.RECS_ROW_GROUP_ADD_COMMENT,
            ])}
          >
            <Button
              size="md"
              onClick={() => {
                if (gridRef && gridRef.current && gridRef.current.api && gridRef.current.api.getSelectedRows().length === 1) {
                  setSelectedRow(gridRef.current.api.getSelectedRows()[0]);
                  modalInstance(MODALS.ADD_NOTE).show();
                } else {
                  uiStore.addNotification("error", "Please select 1 row");
                }
              }}
              disabled={processedRowButtonsDisabled}
            >
              <IconFileImportSetup className={`btn-md-svg`} />
              <Text size="sm" weight="medium">
                Tags
              </Text>
            </Button>
          </AuthWrapper>
          <span className="pr-8"></span>
          <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_ROW_GROUP_REMOVE])}>
            <Button
              size="md"
              onClick={() => {
                removeMatchedRows();
              }}
              disabled={processedRowButtonsDisabled}
              color="danger"
            >
              <>
                {loading ? <Spinner color="white" backgroundColor="red" /> : <IconCloseRemove className={`btn-md-svg`} />}
                <Text size="sm" weight="medium">
                  Remove processed rows
                </Text>
              </>
            </Button>
          </AuthWrapper>
        </>
      )}
      <span className="pr-8"></span>
      <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_REC_SUBMIT])}>
        <Button
          size="md"
          onClick={() => {
            submitRec();
          }}
          disabled={submitRecDisabled}
          color={"success-primary"}
        >
          <Text size="sm" weight="medium">
            Submit Rec
          </Text>
        </Button>
      </AuthWrapper>
      <AddNotePanel teamId={teamId} rowGroup={selectedRow ? selectedRow : null} updateTable={updateTable} />
    </>
  );
};

export { PendingRecToolbar, OpenRecToolbar, CompletedRecToolbar };
